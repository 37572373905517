import { useCallback, useEffect } from 'react';

const useInfiniteTransactionScroll = (callback, loading) => {
    const loadMore = useCallback(
        ([entry]) => {
            if (entry.isIntersecting && !loading) {
                callback();
            }
        },
        [callback, loading],
    );

    //eslint-disable-next-line
    const observer = new IntersectionObserver(loadMore, {
        root: null,
        rootMargin: '0px',
        threshold: 0.25,
    });

    const ref = useCallback(
        loadMoreRef => {
            if (loadMoreRef) {
                observer.observe(loadMoreRef);
            }
        },
        [observer],
    );

    useEffect(() => {
        return () => {
            observer.disconnect();
        };
    }, [observer]);

    return ref;
};

export default useInfiniteTransactionScroll;
