import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from 'lib/languages/useTranslate';

import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';

import { getAccountPostError } from 'lib/selectors';
import { showModal } from '../../../../actions/modal';
import { ADD_NEW_CARDHOLDER } from '../../../../constants/shared/modalTypes';

const CardHolderDeclarationModal = ({ handleSubmit }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cardholderDeclarationModal');
    const error = useSelector(getAccountPostError);

    useEffect(() => {
        if (error) {
            dispatch(
                showModal({
                    type: ADD_NEW_CARDHOLDER,
                    props: {
                        handleSubmit: handleSubmit,
                    },
                }),
            );
        }
    }, [error, dispatch, handleSubmit]);

    return (
        <ModalOuterContainer
            handleSubmit={handleSubmit}
            className="declaration-modal"
        >
            <Typography tag="h2">{translate('titleText')}</Typography>

            <Typography tag="p">{translate('infoText')}</Typography>
        </ModalOuterContainer>
    );
};

export default CardHolderDeclarationModal;
