import React from 'react';
import dayjs from 'dayjs';

import { cardSatusValues } from 'lib/constants';
import { formattedCardNumber } from 'lib/utils';
import useTranslate from 'lib/languages/useTranslate';
import Button from 'components/shared/generic/button/presentational/Button';

const { LOST, STOLEN, DAMAGED } = cardSatusValues;

const EndedCard = ({ position, card, onClick, isDarkText }) => {
    const cardNumber = formattedCardNumber(card.cardPan);
    const translate = useTranslate('cards.cardSlider');

    const { expiryDate, status } = card;

    return (
        <div className={`content frozen-card ${isDarkText ? 'dark-text' : ''}`}>
            <i className="icon fa fa-lock-alt"></i>
            <p className="expiry-date">{dayjs(expiryDate).format('YY/MM')}</p>
            <span className="card-number">{cardNumber}</span>
            <p className="name">{card.cardHolderName}</p>
            <p className="title">
                {translate(
                    status === LOST
                        ? 'cardLost'
                        : status === STOLEN
                        ? 'cardStolen'
                        : status === DAMAGED
                        ? 'cardDamaged'
                        : 'cardEnded',
                )}
            </p>
            {position === 'center' && (
                <Button onClick={onClick}>
                    {translate('orderReplacement')}
                </Button>
            )}
        </div>
    );
};

export default EndedCard;
