import React from 'react';
import { stringCaptialize } from '../../../../../utils/generic';
import { formatMoney } from 'lib/utils';
import { formatDate } from 'lib/utils/customDate';

const PendingTransactionListItem = ({ item, keyIndex }) => {
    return (
        <div className="transaction-container">
            <input
                id={`collapsible-${keyIndex}`}
                className="toggle"
                type="checkbox"
            />
            <label
                htmlFor={`collapsible-${keyIndex}`}
                className="transaction-toggle"
            >
                <div className="transaction-left">
                    <p className="transaction-name">
                        {item.recipient
                            ? stringCaptialize(item.recipient)
                            : 'Transaction'}
                    </p>
                </div>
                <div className="transaction-right">
                    <div className="transaction-item-container">
                        <p className="transaction-type"></p>
                    </div>
                    <div className="transaction-item-container">
                        <p
                            className={`transaction-amount ${
                                item.value > 0 ? 'green' : ''
                            }`}
                        >
                            {`${item.value > 0 ? '+' : ''}${formatMoney(
                                item.value,
                            )} GBP`}
                        </p>
                    </div>
                </div>
            </label>
            <div className="collapsible-content">
                <div className="content-inner">
                    <p>
                        Date - <strong>{formatDate(item.createdDate)}</strong>
                    </p>
                    {!!item.reference && (
                        <p>
                            Reference - <strong>{item.reference}</strong>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PendingTransactionListItem;
