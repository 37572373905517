import { Link } from "react-router-dom";
import analytics from "utils/analytics";

export enum ButtonTypes {
  BUTTON = "button",
  SUBMIT = "submit",
  RESET = "reset",
}

enum TrackingAction {
  BUTTON_CLICK = "BUTTON_CLICK",
}

interface IProps {
  children: string;
  type?: ButtonTypes;
  className?: string;
  isPosting?: boolean;
  disabled?: boolean;
  onClick?: (a?) => object | void;
  to?: string | null;
  href?: string | null;
  size?: string;
  tracking?: boolean;
  trackingAction?: TrackingAction;
  trackingLabel?: TrackingAction;
  target?: string;
}

const Button = ({
  children,
  type = ButtonTypes.BUTTON,
  className = "",
  isPosting = false,
  disabled = false,
  onClick,
  to = null,
  href = null,
  size = "normal",
  tracking = false,
  trackingAction = TrackingAction.BUTTON_CLICK,
  trackingLabel = TrackingAction.BUTTON_CLICK,
  target = "_blank",
}: IProps) => {
  const handleClick = (event) => {
    !!onClick && onClick(event);
    if (tracking) {
      analytics.sendButtonEvent(trackingAction, trackingLabel);
    }
  };

  if (to) {
    return (
      <Link
        className={`sm-button ${size} ${className} ${isPosting || disabled ? 'link-disabled' : ''}`}
        type={type}
        onClick={handleClick}
        to={to}
      >
        {isPosting ? <i className="far fa-spinner fa-spin" /> : children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        className={`sm-button ${size} ${className}`}
        type={type}
        onClick={handleClick}
        href={href}
        target={target}
      >
        {isPosting ? <i className="far fa-spinner fa-spin" /> : children}
      </a>
    );
  }
  return (
    <button
      className={`sm-button ${size} ${className}`}
      type={type}
      onClick={handleClick}
      disabled={isPosting || disabled}
    >
      {isPosting ? <i className="far fa-spinner fa-spin" /> : children}
    </button>
  );
};

export default Button;
