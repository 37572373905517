import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, usePrevious, is5xxError } from 'lib/utils';
import {
    getPayeesIsPosting,
    getPayeesPostError,
    getPayeesPostSuccess,
} from 'lib/selectors/payees';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';
import { PayeeType } from 'lib/type/enums';
import { useVerifyPayeeMutation } from 'lib/services/payee';
import { createPayee } from 'lib/actions';
import { resetPayeeState, selectUnverifiedNewPayee } from 'lib/slices';
import CopCheckModal from './CopCheckModal';
import CreatePayee from '../presentational/CreatePayee';
import {
    PAYMENT_CONFIRMATION_OF_PAYEE,
    PAYMENT_MANAGE_PAYEES,
} from '../../../../../constants/shared/routes';
import { getApiVersion } from 'lib/selectors';

const initialModalData = {
    show: false,
    isPayeeCreated: false,
};

const CreatePayeeContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const createPayeeError = useSelector(getPayeesPostError);
    const isCreatingPayee = useSelector(getPayeesIsPosting);
    const postSuccess = useSelector(getPayeesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const translate = useTranslate('addPayee');
    const backRoute = location.state ? location.state.backRoute : null;
    const [modalState, setModalState] = useState(initialModalData);

    const [verifyPayee] = useVerifyPayeeMutation();

    const unverifiedNewPayee = useSelector(selectUnverifiedNewPayee) || {};
    const isAccountV2 = useSelector(getApiVersion) === '/v2';
    const isCopModalVisible = modalState.show && !createPayeeError;

    const [formData, handleChange] = useForm({
        type: unverifiedNewPayee?.type ?? PayeeType.PERSONAL,
        payeeName: unverifiedNewPayee?.name ?? '',
        sortCode: unverifiedNewPayee?.sortCode ?? '',
        payeeAccountNo: unverifiedNewPayee?.accountNumber ?? '',
    });

    const { payeeName, sortCode, payeeAccountNo, type } = formData;
    const cleanedSortCode = sortCode.replace(/-/g, '');
    const cleanedAccountNo = payeeAccountNo.replace(/\s/g, '');

    const handlePayeeVerification = async () => {
        verifyPayee({
            name: payeeName,
            sortCode: cleanedSortCode,
            accountNumber: cleanedAccountNo,
            type,
        })
            .unwrap()
            .then(({ matched }) => {
                if (!matched) {
                    setModalState(initialModalData);
                    return history.push(PAYMENT_CONFIRMATION_OF_PAYEE);
                }
                handlePayeeCreation();
            })
            .catch(error => {
                setModalState(initialModalData);
                if (is5xxError(error.status))
                    history.push(PAYMENT_CONFIRMATION_OF_PAYEE);
                else alert(translate("errorVerifyPayee"));
            });
    };

    const handlePayeeCreation = async () => {
        await dispatch(
            createPayee({
                payeeName,
                sortCode: cleanedSortCode,
                payeeAccountNo: cleanedAccountNo,
                type,
            }),
        );
    };

    const handleSubmit = async () => {
        setModalState({ ...modalState, show: true });
        try {
            if (isAccountV2) await handlePayeeVerification();
            else await handlePayeeCreation();
        } catch (error) {
            setModalState(initialModalData);
        }
    };

    const handlePayeeCreationSuccess = useCallback(() => {
        setModalState(initialModalData);
        dispatch(resetPayeeState());
        history.push(PAYMENT_MANAGE_PAYEES);
    }, [history, dispatch]);

    useEffect(() => {
        let timer;
        if (postSuccess && !prevPostSuccess) {
            timer = setTimeout(async () => {
                setModalState({ ...modalState, isPayeeCreated: true });
                await new Promise(resolve => setTimeout(resolve, 2000));
                handlePayeeCreationSuccess();
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [postSuccess, prevPostSuccess, modalState, handlePayeeCreationSuccess]);

    return (
        <PageContainer className="manage-payee-container">
            <SectionTitle
                title={translate('titleText')}
                backRoute={backRoute}
            />
            <CreatePayee
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                createPayeeError={createPayeeError}
                isCreatingPayee={isCreatingPayee}
                isAccountV2={isAccountV2}
            />
            {isCopModalVisible && (
                <CopCheckModal
                    isAccountV2={isAccountV2}
                    isPayeeCreated={modalState.isPayeeCreated}
                />
            )}
        </PageContainer>
    );
};

export default CreatePayeeContainer;
