import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchPin, clearPinData } from 'lib/actions';
import {
    getCardsIsFetchingPin,
    getCardsPin,
    getCardsPinError,
} from 'lib/selectors';
import { useForm, usePrevious } from 'lib/utils';
import { CARDS_PIN_OPTIONS } from 'constants/shared/routes';
import EnterCVCModal from '../presentational/EnterCVCModal';

const EnterCVCModalContainer = ({ hideModal, cardSerial }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pin = useSelector(getCardsPin);
    const isFetching = useSelector(getCardsIsFetchingPin);
    const error = useSelector(getCardsPinError);
    const [form, handleChange] = useForm({
        cvc: '',
    });

    const handleSubmit = () => {
        const { cvc } = form;

        dispatch(fetchPin(cardSerial, cvc));
    };

    const prevPin = usePrevious(pin);

    useEffect(() => {
        if (!prevPin && pin) {
            history.push(CARDS_PIN_OPTIONS, { authCVC: true, pin: pin });
            dispatch(hideModal());
        }
    }, [pin, dispatch, history, hideModal, prevPin]);

    useEffect(() => {
        dispatch(clearPinData());

        return () => {
            dispatch(clearPinData());
        };
    }, [dispatch]);

    return (
        <EnterCVCModal
            {...form}
            error={error}
            isPosting={isFetching}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
};

export default EnterCVCModalContainer;
