import { HTMLAttributes, FC } from "react";

interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: string;
}

const Component: FC<ComponentProps> = ({ as: Tag = "div", ...otherProps }) => {
  return <Tag {...otherProps} />;
};

export default Component;
