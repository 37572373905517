import Typography from "components/shared/generic/typography/presentational/Typography";
import Form from "components/shared/form/presentational/Form";
import Field from "components/shared/form/presentational/Field";
import useTranslate from "lib/languages/useTranslate";
import CodeInput from "components/shared/form/presentational/CodeInput";
import DatePicker from 'components/shared/form/presentational/DatePicker';
import { useSelector } from "react-redux";
import { getTheme } from 'selectors/darkMode';

interface ActivateAccountFormProps {
  dob: string,
  handleChange: () => void;
  handleSubmit: () => void;
  isPosting: boolean;
  error: any;
}

export const AccountActivation = ({
  dob,
  handleChange,
  handleSubmit,
  isPosting,
  error,
}: ActivateAccountFormProps) => {
  const translate = useTranslate("activateAccount");
  const isDarkModeEnabled = useSelector(getTheme);
  return (
    <div className="login-container">
      <Typography tag="h1" className={isDarkModeEnabled ? 'text-white' : 'text-dark'}>{translate("titleText")}</Typography>
      <Typography tag="p" className={isDarkModeEnabled ? 'text-white' : 'text-dark'}>{translate("descriptionText")}</Typography>
      <Form
        buttonSize="full"
        submitText={translate("form.confirm")}
        onSubmit={handleSubmit}
        isPosting={isPosting}
        error={error}
      >
        <Field required styles={{ textAlign: "center" }}>
          <CodeInput
            required
            classes="code-input"
            fields={4}
            width={60}
            height={60}
            name="activationCode"
            onChange={handleChange}
          />
        </Field>
        <Field htmlFor={'dob'} required styles={{ textAlign: "center" }}>
            <DatePicker
                required
                placeholderText={translate('form.dob')}
                onChange={handleChange}
                name="dob"
                value={dob}
                classes="generic-input"
                omitClear
            />
        </Field>
      </Form>
    </div>
  );
};
