import React from 'react';
import useSearchAndList from 'utils/hooks/useSearchAndList';
import SearchAndListModal from '../presentational/SearchAndListModal';

const SearchAndListModalContainer = ({
    handleClick,
    onSearchChange,
    handleCloseModal,
    popularList = [],
    othersList = [],
}) => {
    const { searchTerm, displayedList, handleSearchTerm } = useSearchAndList(
        onSearchChange,
    );

    return (
        <div className="search-and-list-modal">
            <SearchAndListModal
                handleCloseModal={handleCloseModal}
                handleSearchTerm={handleSearchTerm}
                handleClick={handleClick}
                searchTerm={searchTerm}
                result={displayedList}
                popularList={popularList}
                othersList={othersList}
            />
        </div>
    );
};

export default SearchAndListModalContainer;
