import { FC } from 'react';
import { cardImages, cardPos } from 'constants/shared/enums';
import Card from '../presentational/Card';
import { accountRoles } from 'lib/constants';

import PlaceholderVerticalCard from '_content/images/cards/SM_Placeholder.png';

interface CardI {
    cardSerial?: string;
}

interface Account {
    productType: string;
}

interface CarouselContainerProps {
    cards: CardI[];
    account: Account;
    handleCardClick: (position: string, index: number) => void;
}

const CarouselContainer: FC<CarouselContainerProps> = ({
    cards,
    account,
    handleCardClick,
}) => {
    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {cards.map((card, index) => {
                    const position = cardPos[index];
                    return (
                        <div
                            key={card?.cardSerial}
                            className={`carousel-item ${position}`}
                            onKeyDown={() => handleCardClick(position, index)}
                            onClick={() => handleCardClick(position, index)}
                            role="button"
                        >
                            {!!card && (
                                <div
                                    className={`card-content ${
                                        account.productType ===
                                        accountRoles.Premuim
                                            ? 'primary'
                                            : ''
                                    }`}
                                >
                                    <img
                                        src={
                                            !card.cardSerial
                                                ? PlaceholderVerticalCard
                                                : cardImages[
                                                      account.productType
                                                  ]
                                        }
                                        alt="suits-me card"
                                    />
                                    <Card
                                        card={card}
                                        account={account}
                                        position={position}
                                    ></Card>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CarouselContainer;
