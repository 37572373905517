import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import { formatMoney, formatSortCode } from 'lib/utils';
import { currencies } from 'lib/constants';
import { IAccountList } from 'lib/type/types';

interface IAccountSelectModalProps {
    handleModalClose: (e: React.MouseEvent) => void;
    accounts: IAccountList[];
}

const AccountSelectModal = ({
    handleModalClose,
    accounts,
}: IAccountSelectModalProps) => {
    return (
        <ModalOuterContainer customButtons>
            <div className="description">
                <Typography tag="h2">Accounts</Typography>
            </div>
            <div className="account-list">
                {!!accounts.length &&
                    accounts.map(account => (
                        <div key={account.accountID} className="account-item">
                            <div className="accountItemInnerContainer">
                                <Typography tag="span">
                                    Account number
                                </Typography>
                                <Typography tag="p">
                                    {account.bankAccountNumber}
                                </Typography>
                            </div>
                            <div className="accountItemInnerContainer">
                                <Typography tag="span">Sort Code</Typography>
                                <Typography tag="p">
                                    {formatSortCode(account.bankSortCode)}
                                </Typography>
                            </div>
                            <Typography tag="p">
                                {currencies[account.currencyCode] +
                                    formatMoney(account.balance)}
                            </Typography>
                        </div>
                    ))}
            </div>
            <Button onClick={e => handleModalClose(e)}>Cancel</Button>
        </ModalOuterContainer>
    );
};

export default AccountSelectModal;
