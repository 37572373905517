import React from 'react';
import { useSelector } from 'react-redux';
import BulletIcon from '_content/images/icons/sm-bullet.svg';
import WhiteBulletIcon from '_content/images/icons/sm-bullet-darkmode.svg';

import { getTheme } from 'selectors/darkMode';

import Typography from 'components/shared/generic/typography/presentational/Typography';
import { formatSortCode } from 'lib/utils';
import useTranslate from 'lib/languages/useTranslate';

const AccountDetails = ({ data, flag }) => {
    const {
        sortCode,
        bankAccountNo,
        accountName,
        clearedBalanceCurrencyCode,
    } = data;
    const translate = useTranslate('accountDetails');
    const isDarkModeEnabled = useSelector(getTheme);

    return (
        <>
            <div className="account-details-name">
                <Typography tag="h1">{accountName}</Typography>
                <div className="currency">
                    <img
                        src={flag?.flag}
                        alt="country flag"
                        className="currency-flag currency-flag-gbp"
                    />
                    <Typography styles={{ fontWeight: 700 }}>
                        {clearedBalanceCurrencyCode}
                    </Typography>
                </div>
            </div>
            <div className="account-details">
                <Typography tag="h1">{translate('accountNumber')}</Typography>
                <Typography>{bankAccountNo}</Typography>
            </div>
            <div className="account-details">
                <Typography tag="h1">{translate('sortCode')}</Typography>
                <Typography>{formatSortCode(sortCode)}</Typography>
            </div>
            <div className="knowledge-section">
                <Typography tag="h3">
                    {translate('knowledgeSection.title')}
                </Typography>
                <ul>
                    <li>
                        <img
                            src={
                                isDarkModeEnabled ? WhiteBulletIcon : BulletIcon
                            }
                            alt="bullet-list"
                        />
                        {translate('knowledgeSection.bulletPoint1')}
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AccountDetails;
