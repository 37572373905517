import { AuthStep } from 'constants/shared/enums';
import dayjs from 'dayjs';
import { isEmpty } from 'lib/utils';

export const captialize = name => name.charAt(0).toUpperCase() + name.slice(1);

export const stringCaptialize = string =>
    string
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));

export const formatAccount = account => {
    if (isEmpty(account)) return account;

    return {
        ...account,
    };
};

export const checkCurrenciesByKeys = (obj, keys, term) => {
    if (isEmpty(obj) || isEmpty(term)) return;

    return keys.reduce((result, key) => {
        const objKey = obj[key].toLowerCase();
        const objTerm = term.toLowerCase();
        if (!result) {
            result = objKey.includes(objTerm);
        }
        return result;
    }, false);
};

export const searchCurrencies = (list, term, keys = []) => {
    if (isEmpty(keys)) return;
    return list.reduce((result, item) => {
        if (checkCurrenciesByKeys(item, keys, term)) {
            result = [...result, item];
        }
        return result;
    }, []);
};

export const formatAlphabetList = (arr, key) => {
    if (!arr || !key) return;

    const response = arr.reduce((result, item) => {
        const uniqFirstChar = item.fullName.charAt(0).toUpperCase();
        if (Object.prototype.hasOwnProperty.call(result, uniqFirstChar)) {
            result = {
                ...result,
                [uniqFirstChar]: [...result[uniqFirstChar], item],
            };
        } else {
            result = { ...result, [uniqFirstChar]: [item] };
        }
        result[uniqFirstChar].sort((a, b) => a - b);
        return result;
    }, {});

    return Object.fromEntries(Object.entries(response));
};

const compareArrayValues = (obj1, obj2, key) => {
    const value1 = obj1[key].toLowerCase();
    const value2 = obj2[key].toLowerCase();

    if (value1 > value2) {
        return 1;
    } else if (value1 < value2) {
        return -1;
    }
};

export const sortArrayOfObjs = (array, key) =>
    array.sort((a, b) => compareArrayValues(a, b, key));

export const formatClassName = (props, keys) => {
    if (!props || !keys) return;

    const classes = keys.reduce((result, value) => {
        if (props[value]) {
            result = [...result, value];
        }

        return result;
    }, []);

    return classes.join(' ');
};

export const downloadFile = (url, fileName) => {
    const blobData = [url];
    const blob = new Blob(blobData, {
        type: fileName.toLowerCase().endsWith('csv')
            ? 'text/csv'
            : 'application/pdf',
    });
    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        (window.navigator as any).msSaveBlob(blob, fileName);
    } else {
        const blobURL =
            window?.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
};

export const toCamelCase = string => {
    return string
        .replace(/[a-z][A-Z]/g, chars => chars.split('').join(' ')) // PascalCase > regular case
        .toLowerCase()
        .replace(/[-_ ][a-z]/g, char => char.charAt(1).toUpperCase()); // regular case || kebab-case || snake_case > camelCase
};

export const isValidDate = date => {
    return date instanceof Date && !isNaN(dayjs(date).valueOf());
};

export const leadingDebounce = (func, timeout = 500) => {
    let timer;

    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }

        clearTimeout(timer);

        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
};

export const setAuthStep = (step: AuthStep | null) => {
    if(!step) {
        localStorage.removeItem('authStep')
        return
    }
    localStorage.setItem('authStep', step);
};
