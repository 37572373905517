import { AuthStep } from 'constants/shared/enums';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN, AUTH_OTP, AUTH_ACTIVATION, AUTH_ACTIVATION_SUCCESS, AUTH_EMAIL } from '../../../../constants/shared/routes';

const getRouteForStep = (step: AuthStep) => {
    switch (step) {
        case AuthStep.EMAIL_SIGN_IN_FLOW:
        case AuthStep.EMAIL_ACTIVATION_FLOW:
            return AUTH_EMAIL;
        case AuthStep.OTP:
            return AUTH_OTP;
        case AuthStep.ACTIVATION:
            return AUTH_ACTIVATION;
        case AuthStep.ACTIVATION_SUCCESS:
            return AUTH_ACTIVATION_SUCCESS;
        default:
            return LOGIN;
    }
}

export const ActivationGuard = ({ children }) => {
    const history = useHistory();
    const cognitoToken = localStorage.getItem('cognitoToken');
    const authStep = localStorage.getItem('authStep') as AuthStep;
    const currentStep = getRouteForStep(authStep);

    useEffect(() => {
        if (!cognitoToken) {
            history.push(LOGIN);
        }
    }, [history, cognitoToken]);

    useEffect(() => {
        history.push(currentStep);
    }, [currentStep, history])

    return children;
};
