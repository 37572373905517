import React, { useState } from 'react';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';

const PinOptions = ({ pin = '1010' }) => {
    const [pins, setPins] = useState({
        pin0: false,
        pin1: false,
        pin2: false,
        pin3: false,
    });

    const handleToggle = index => {
        setPins({
            ...pins,
            [`pin${index}`]: !pins[`pin${index}`],
        });
    };

    return (
        <div>
            <div className="pin-header">
                <SectionTitle title="View Pin" backRoute="/cards" />
                <p>To view your PIN click the boxes to reveal the numbers</p>
            </div>
            <div className="pin-container">
                {pin.split('').map((item, index) => (
                    <div
                        key={index}
                        className="pin-item"
                        onClick={() => handleToggle(index)}
                    >
                        {pins[`pin${index}`] ? item : '＊'}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PinOptions;
