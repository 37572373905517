import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import TextInput from 'components/shared/form/presentational/TextInput';
import MaskInput from 'components/shared/form/presentational/MaskInput';
import useTranslate from 'lib/languages/useTranslate';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Radio from 'components/shared/form/presentational/Radio';
import { PayeeType } from 'lib/type/enums';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPayeeVerificationErrorInfo, selectUnverifiedNewPayee } from 'lib/slices';

enum RadioButtonLabel {
    PERSON = 'Person',
    BUSINESS = 'Business',
}

const CreatePayee = ({
    formData: { type, payeeName, sortCode, payeeAccountNo },
    handleChange,
    handleSubmit,
    createPayeeError,
    isCreatingPayee,
    isAccountV2
}) => {
    const translate = useTranslate('addPayee');
    const [selectedType, setSelectedType] = useState(type);
    const { validationErrors } = useSelector(selectPayeeVerificationErrorInfo);
    const { type: previousType, name: previousName } = useSelector(selectUnverifiedNewPayee) || {};
    const { name: nameValidationError, type: typeValidationError } = validationErrors || {};
    const showWrongNameErr = nameValidationError && payeeName === previousName;
    const showWrongTypeErr = typeValidationError && selectedType === previousType;
    

    const options = [
        {id: PayeeType.PERSONAL, text: RadioButtonLabel.PERSON, value: PayeeType.PERSONAL},
        {id: PayeeType.BUSINESS, text: RadioButtonLabel.BUSINESS, value: PayeeType.BUSINESS},
    ];

    const onRadioBtnChange = (type: PayeeType) => {
        setSelectedType(type);
        handleChange('type', type);
    }

    return (
        <Form
            submitText={translate('submitText')}
            onSubmit={handleSubmit}
            isPosting={isCreatingPayee}
            error={createPayeeError}
            disabledButton={showWrongNameErr || showWrongTypeErr}
        >
            {isAccountV2 && (
                <>
                    <Typography className="paragraphPayee">{translate('radioText')}</Typography>
                    <Field htmlFor="id">
                        <Radio
                            className="create-payee-radio"
                            name="type"
                            options={options}
                            onChange={onRadioBtnChange}
                            value={selectedType}
                        />
                        {showWrongTypeErr && (
                            <Field>
                                <p className="error">Type does not match account provided</p>
                            </Field>
                        )}
                    </Field>
                </>
            )}
            <Field name={translate('name')}>
                <TextInput
                    classes={`generic-input bg ${showWrongNameErr && 'error-Input'}`}
                    required
                    name="payeeName"
                    placeholder={translate('namePlaceholder')}
                    value={payeeName}
                    onChange={handleChange}
                    minLength={6}
                    maxLength={18}
                    info={showWrongNameErr ? '' : translate('info')}
                />
                {showWrongNameErr && (
                    <Field>
                        <p className="error">Name does not match account provided</p>
                    </Field>
                )}
            </Field>
            <Field name={translate('accountNumber')}>
                <MaskInput
                    required
                    placeholder={translate('accountNumberPlaceholder')}
                    onChange={handleChange}
                    name="payeeAccountNo"
                    value={payeeAccountNo}
                    classes="generic-input bg"
                    mask="9999 9999"
                    minLength={8}
                />
            </Field>
            <Field name={translate('sortCode')}>
                <MaskInput
                    required
                    placeholder={translate('sortCodePlaceholder')}
                    onChange={handleChange}
                    name="sortCode"
                    value={sortCode}
                    classes="generic-input bg"
                    mask="99-99-99"
                />
            </Field>
            <Typography className="warning create-payee-warning text-center">
                {translate('warningMessage')}
            </Typography>
        </Form>
    );
};

export default CreatePayee;