import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTheme } from 'selectors/darkMode';
import useUpdateLanguage from 'components/activity/more/settings/hooks/useUpdateLanguage';

import Logo from '_content/images/logos/SuitsMe_Logo_Whiteout.svg';

import AccountOverviewContainer from './AccountOverviewContainer';
import TabNavbar from './TabNavbar';
import LanguageSelect from 'components/shared/form/presentational/LanguageSelect';

const AuthorizedHeader = () => {
    const isDarkModeEnabled = useSelector(getTheme);
    const [language, handleLanguageChange] = useUpdateLanguage();

    return (
        <header
            className={`authed-header ${isDarkModeEnabled ? 'dark-mode' : ''}`}
        >
            <div className="item-logo">
                <Link to="/">
                    <img src={Logo} alt="Logo" />
                </Link>
            </div>
            <div className="item-account">
                <AccountOverviewContainer />
                <TabNavbar />
            </div>
            <div className="item-notifications">
                <LanguageSelect
                    name="languageCode"
                    onChange={handleLanguageChange}
                    value={language}
                    required
                />
            </div>
        </header>
    );
};

export default AuthorizedHeader;
