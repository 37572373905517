import React from 'react';

import dayjs from 'dayjs';

import Button from 'components/shared/generic/button/presentational/Button';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { formatMoney } from 'lib/utils';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';

const DirectDebitItem = ({ data, showCancelModal }) => {
    return (
        <PageContainer>
            <div className="direct-debit-container">
                <SectionTitle
                    title={`${data.payeeName}${data.amount ? " - £" + formatMoney(data.amount) : ''}`}
                    backRoute={'/payments/direct-debit'}
                />

                <div className="view-direct-debit-container">
                    <div className="view-item">
                        <h2>
                            Status:{' '}
                            {data.status[0] +
                                data.status.slice(1).toLowerCase()}
                        </h2>
                    </div>
                    <div className="view-item">
                        <h2>{data.payeeName}</h2>
                        <h3>
                            {dayjs(data.paymentDueDate).format('DD MMM YYYY')}
                        </h3>
                    </div>
                    <div className="view-item">
                        <h2>Reference</h2>
                        <h3>{data.reference}</h3>
                    </div>
                    <div className="view-item">
                        <h2>Created</h2>
                        <h3>{dayjs(data.created).format('DD MMM YYYY')}</h3>
                    </div>
                </div>

                {data.status === 'ACTIVE' && (
                    <Button className="danger" onClick={showCancelModal}>
                        Cancel Direct Debit
                    </Button>
                )}
            </div>
        </PageContainer>
    );
};

export default DirectDebitItem;
