import Typography from 'components/shared/generic/typography/presentational/Typography';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

interface IRecommendedPayeeContainerProps {
    recommendedPayeeDetails: {
        name: string,
        type: string,
        accountNumber: string,
        sortCode: string,
    };
    onCreatePayee: () => void;
    errorStyle: string;
}

const RecommendedPayeeContainer = ({
    recommendedPayeeDetails: { name, type, accountNumber, sortCode },
    onCreatePayee,
    errorStyle,
}: IRecommendedPayeeContainerProps) => {
    const translate = useTranslate('addPayee');
    
    return (
        <>
            <Typography className="detail-header-text">
                {translate("recommended")}
            </Typography>
            <div className="confirmation-of-payee-detail-box">
                <Typography className="payee-title bg-white">{translate("name")}</Typography>
                <Typography className="payee-name bg-white">{name}</Typography>
                <Typography className="payee-title bg-white">{translate("accountType")}</Typography>
                <Typography className="payee-type bg-white">{type}</Typography>
                <div className="account-sort-code">
                    <div className="accountSortCodeBox">
                        <Typography className="payee-title bg-white">
                            {translate("sortCode")}
                        </Typography>
                        <Typography className="sortCode bg-white">{sortCode}</Typography>
                    </div>
                    <div className="accountSortCodeLine"></div>
                    <div className="accountSortCodeBox">
                        <Typography className="payee-title bg-white">
                            {translate("accountNumber")}
                        </Typography>
                        <Typography className={`account-number ${errorStyle} bg-white`}>
                            {accountNumber}
                        </Typography>
                    </div>
                </div>
                <div className="button-apply-container">
                    <i className="fa fa-edit"></i>
                    <Button className="apply-button" onClick={onCreatePayee}>
                        {translate("apply")}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RecommendedPayeeContainer;
