import withFieldValidation from '../hocs/withFieldValidation';

interface IOptions {
    text: string;
    value: string;
}

interface RadioProps {
    options?: IOptions[];
    className?: string;
    onChange: (name: string, value: string) => void;
    name: string;
    value: string;
    showError: () => void;
}

const Radio = ({
    options,
    className,
    onChange,
    name,
    value,
    showError,
}: RadioProps) => {
    return (
        <div className="checkbox-list size-lg-12">
            {options?.map(renderButton)}
        </div>
    );

    function renderButton({ text, value: curVal }: IOptions) {
        return (
            <div key={curVal} className={`radio-button ${className}`}>
                <input
                    type="radio"
                    name={name}
                    id={curVal}
                    value={curVal}
                    checked={curVal === value}
                    onChange={() => handleChange(curVal)}
                />
                <div className={'holder'}>
                    <label className="text" htmlFor={curVal}>
                        {text}
                    </label>
                    <span className="outer">
                        <span className="inner" />
                    </span>
                </div>
            </div>
        );
    }

    function handleChange(val: string) {
        onChange(val, name);
        showError();
    }
};

export default withFieldValidation(Radio);