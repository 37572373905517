import Button from 'components/shared/generic/button/presentational/Button';
import AppStoreIcon from '_content/images/app-store/app-store-badge.png';
import GooglePlayStoreIcon from '_content/images/app-store/google-play-badge.png';
import useTranslate from 'lib/languages/useTranslate';
import { getModalProps } from 'selectors/modal'
import { useSelector } from 'react-redux';
import { SUITS_ME_APP_STORE, SUITS_ME_GOOGLE_PLAY_STORE } from 'lib/constants/routes';

const CardFrameModal = ({ handleClose }) => {
    const translate = useTranslate('cards.viewPIN');
    const { phone } = useSelector(getModalProps) as { phone: string };
    
    return (
        <div className='view-pin-modal-outer'>
            <div className="view-pin-modal">
                <p className='headerText'>{translate("info")}</p>
                <div className='app-store'>
                    <a href={SUITS_ME_APP_STORE}><img src={AppStoreIcon} alt='App Store'/></a>
                    <a href={SUITS_ME_GOOGLE_PLAY_STORE}><img src={GooglePlayStoreIcon} alt='Google Play'/></a>
                </div>
                <p>{translate("phoneText", { phone })}</p>
                <Button className='view-pin-modal-close' onClick={handleClose}>{translate("closeBtn")}</Button>
            </div>
        </div>
    );
};

export default CardFrameModal;
