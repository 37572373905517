import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRedirectRoute } from 'selectors/redirect';
import { clearRedirectRoute } from 'actions/redirect';

const GlobalRedirect = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const clear = useCallback(() => dispatch(clearRedirectRoute()), [dispatch]);
    const redirectRoute = useSelector(getRedirectRoute);

    useEffect(() => {
        if (redirectRoute) {
            history.push(redirectRoute);
            clear();
        }
    }, [redirectRoute, clear, history]);

    return <></>;
};

export default GlobalRedirect;
