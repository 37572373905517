import React from 'react';
import { useDispatch } from 'react-redux';

import { showModal } from 'actions/modal';
import {
    ADD_CARDHOLDER_DECLARATION,
    ADD_NEW_CARDHOLDER,
} from 'constants/shared/modalTypes';
import { smUseAddCardHolder } from 'lib/hooks';
import AddCardHolderModal from '../presentational/AddCardHolderModal';

const AddCardHolderModalContainer = ({ hideModal }) => {
    const dispatch = useDispatch();
    const { form, handleChange, handleSubmit, isPosting } = smUseAddCardHolder(
        onSubmit,
        onError,
    );

    const handleClose = () => dispatch(hideModal());

    function onSubmit() {
        handleClose();
    }

    function onError() {
        dispatch(showModal({ type: ADD_NEW_CARDHOLDER, props: {} }));
    }

    function showDeclarationModal() {
        dispatch(
            showModal({
                type: ADD_CARDHOLDER_DECLARATION,
                props: {
                    handleSubmit: handleSubmit,
                },
            }),
        );
    }

    return (
        <AddCardHolderModal
            {...form}
            handleChange={handleChange}
            handleSubmit={showDeclarationModal}
            isPosting={isPosting}
        />
    );
};

export default AddCardHolderModalContainer;
