import React from 'react';
import Accordion from 'components/shared/generic/accordion/presentational/Accordion';

const ZendeskFAQs = ({ faqs }) => {
    return (
        <div className="zendesk-faqs">
            {faqs.map(({ title, body }, index) => (
                <Accordion key={index} title={title} tracking>
                    {<div dangerouslySetInnerHTML={{ __html: body }}></div>}
                </Accordion>
            ))}
        </div>
    );
};

export default ZendeskFAQs;
