import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import More from '_content/images/icons/navigation/more.svg';
import Help from '_content/images/icons/navigation/help.svg';
import Card from '_content/images/icons/navigation/cards.svg';
import Payment from '_content/images/icons/navigation/payment.svg';
import Home from '_content/images/icons/navigation/home.svg';
import MoreActive from '_content/images/icons/navigation/active/more-active.svg';
import HelpActive from '_content/images/icons/navigation/active/help-active.svg';
import CardActive from '_content/images/icons/navigation/active/cards-active.svg';
import PaymentActive from '_content/images/icons/navigation/active/payment-active.svg';
import HomeActive from '_content/images/icons/navigation/active/home-active.svg';

import Curve from '_content/images/icons/navigation-curve.png';
import CurveDark from '_content/images/icons/navigation-curve-dark.png';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import useTranslate from 'lib/languages/useTranslate';

const TabNavbar = () => {
    const { pathname } = useLocation();
    const isDarkModeEnabled = useSelector(getTheme);

    const translate = useTranslate('bottomTabs');

    const navlinks = [
        {
            name: translate('home'),
            icon: Home,
            activeIcon: HomeActive,
            route: '/',
        },
        {
            name: translate('cards'),
            icon: Card,
            activeIcon: CardActive,
            route: '/cards',
        },
        {
            name: translate('payments'),
            icon: Payment,
            activeIcon: PaymentActive,
            route: '/payments',
            activeRoutes: ['payments', 'international'],
        },
        {
            name: translate('help'),
            icon: Help,
            activeIcon: HelpActive,
            route: '/help',
        },
        {
            name: translate('more'),
            icon: More,
            activeIcon: MoreActive,
            route: '/more',
        },
    ];

    return (
        <ul className="nav-links">
            {navlinks.map(
                ({ name, icon, activeIcon, route, activeRoutes }, index) => {
                    const formattedRouteName =
                        route.toLowerCase().split('/')[1] || '/';
                    const formattedPathName =
                        pathname.toLowerCase().split('/')[1] || '/';
                    const isActiveRoutes = activeRoutes
                        ? activeRoutes.includes(formattedPathName)
                        : null;
                    const isActive =
                        isActiveRoutes ||
                        formattedPathName.includes(formattedRouteName);

                    return (
                        <li key={index} className={isActive ? 'active' : ''}>
                            <img
                                className="curve"
                                alt="curve"
                                src={isDarkModeEnabled ? CurveDark : Curve}
                            />
                            <Link to={route} className="link-icon">
                                <span>
                                    <img
                                        src={
                                            isActive && !isDarkModeEnabled
                                                ? activeIcon
                                                : icon
                                        }
                                        alt={name}
                                    />
                                    <p>{name}</p>
                                </span>
                            </Link>
                        </li>
                    );
                },
            )}
        </ul>
    );
};

export default TabNavbar;
