import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getTransactionsList,
    getTransactionsIsFetching,
    getTransactionsFetchError,
    getIsLastPage,
    getIsFetchingPendingTransactions,
    getTotalPendingTransactions,
    getTotalAmountPending,
    getAccount,
    getAccountIsFetching,
    getAccountError,
} from 'lib/selectors';
import useInfiniteTransactionScroll from 'utils/hooks/useInfiniteTransactionScroll';
import AdvertsContainer from '../../adverts/containers/AdvertsContainer';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import InfiniteScroll from 'components/shared/infiniteScroll/presentational/InfiniteScroll';
import Transactions from '../presentational/Transactions';
import useTransactions from '../hooks/useTransactions';
import useTranslate from 'lib/languages/useTranslate';
import { getListItems } from '../../../../../utils/transactions';
import { isEmpty } from 'lib/utils';

const TransactionsContainer = () => {
    const { fetchNextPage } = useTransactions();
    const transactions = useSelector(getTransactionsList);
    const isLastPage = useSelector(getIsLastPage);
    const isFetchingTransactions = useSelector(getTransactionsIsFetching);
    const transactionsError = useSelector(getTransactionsFetchError);
    const translate = useTranslate('transactionsList');

    const account = useSelector(getAccount);
    const isFetchingAccount = useSelector(getAccountIsFetching);
    const accountError = useSelector(getAccountError);

    const isFetchingPendingTransactions = useSelector(
        getIsFetchingPendingTransactions,
    );

    const pendingTransactionsCount = useSelector(getTotalPendingTransactions);
    const pendingTransactionsAmount = useSelector(getTotalAmountPending);

    const ref = useInfiniteTransactionScroll(
        fetchNextPage,
        isFetchingTransactions,
    );

    const formattedTransactions = useMemo(() => getListItems(transactions), [
        transactions,
    ]);

    const items = useMemo(() => {
        let transactions = [...formattedTransactions];

        if (pendingTransactionsCount) {
            const pendingItem = {
                key: 'pending',
                type: 'pending',
                amount: pendingTransactionsAmount,
                count: pendingTransactionsCount,
            };

            transactions = [pendingItem, ...transactions];
        }

        if (!isEmpty(account)) {
            const accountBalanceItem = {
                key: 'accountBalance',
                type: 'accountBalance',
                amount: account.accountBalance,
            };

            transactions = [accountBalanceItem, ...transactions];
        }

        return transactions;
    }, [
        formattedTransactions,
        pendingTransactionsCount,
        pendingTransactionsAmount,
        account,
    ]);

    return (
        <PageContainer className="transactions-container">
            <AdvertsContainer />
            <DataCheck
                data={items}
                isLoading={
                    isFetchingTransactions ||
                    isFetchingPendingTransactions ||
                    isFetchingAccount
                }
                error={transactionsError || accountError}
                noDataMessage={translate('transactionsList')}
                errorMessage={translate('error')}
            >
                <InfiniteScroll
                    className="transactions-scroll-container"
                    ref={ref}
                    last={isLastPage}
                >
                    <Transactions data={items} />
                </InfiniteScroll>
            </DataCheck>
        </PageContainer>
    );
};

export default TransactionsContainer;
