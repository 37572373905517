import { useState } from 'react';

const useAdverts = list => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleChange = isRight => {
        if (currentSlide < 0) {
            setCurrentSlide(0);
            return;
        }
        if (isRight) {
            if (currentSlide < list.length - 2)
                setCurrentSlide(currentSlide + 2);
            else setCurrentSlide(list.length - 3);
            return;
        } else {
            if (currentSlide >= 2) setCurrentSlide(currentSlide - 2);
            else setCurrentSlide(0);
            return;
        }
    };

    return {
        currentSlide,
        handleChange,
    };
};

export default useAdverts;
