import React from 'react';
import { formatMoney, formatSortCode } from 'lib/utils';
import { accountRolesLookup, currencies } from 'lib/constants';
import useTranslate from 'lib/languages/useTranslate';
import { toCamelCase } from 'utils/generic';

const AccountOverview = ({
    handleShowModal,
    account: {
        accountName,
        bankAccountNo,
        sortCode,
        availableBalance,
        clearedBalanceCurrencyCode,
        productType,
    },
    accounts
}) => {
    const translate = useTranslate('accountOverview');
    return (
        <div className="account-overview-container">
            <div
                className={`account-select ${
                    accounts.length > 1 ? 'multi-accounts' : ''
                }`}
                onClick={accounts.length > 1 ? e => handleShowModal(e) : null}
            >
                <div
                    className={`account-details-wrapper ${
                        accounts.length ? 'multi-accounts' : ''
                    }`}
                >
                    <p>
                        <strong>
                            {accountName || 'Unknown'} -{' '}
                            {translate(
                                toCamelCase(
                                    accountRolesLookup[productType] || '',
                                ),
                            )}
                        </strong>
                    </p>
                    {accounts.length > 1 && (
                        <i className="far fa-angle-down icon"></i>
                    )}
                </div>
                <p className="account-number">
                    {translate('accountNo', {
                        bankAccountNo,
                        sortCode: formatSortCode(sortCode),
                    })}
                </p>
            </div>
            <p className="amount-label">{translate('amountLabel')}</p>
            <p className="amount">
                <strong>
                    {currencies[clearedBalanceCurrencyCode]}
                    {formatMoney(availableBalance)}
                </strong>
            </p>
        </div>
    );
};

export default AccountOverview;
