import React from 'react';
import { useDispatch } from 'react-redux';
import { activateCard } from 'lib/actions';

import Button from 'components/shared/generic/button/presentational/Button';
import { hideModal, showModal } from 'actions/modal';
import { CONFIRM_MODAL, CARD_FRAME } from 'constants/shared/modalTypes';
import useTranslate from 'lib/languages/useTranslate';
import { CardProvider } from 'lib/type/enums';
import { REACT_APP_ACTIVATE_CARD_PHONE_NUMBER } from 'config';

const IssuedCard = ({ position, card, isDarkText }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cards');

    return (
        <div className={`content issued-card ${isDarkText ? 'dark-text' : ''}`}>
            <i className="icon fa fa-lock-alt"></i>
            <p className="title">{translate('cardSlider.cardNotActivated')}</p>
            {position === 'center' && (
                <Button onClick={showActivateModal}>
                    {translate('cardSlider.activateCard')}
                </Button>
            )}
        </div>
    );

    function showActivateModal(e) {
        e.preventDefault();
        const isCardProviderTribe = card.provider === CardProvider.TRIBE;
        dispatch(
            showModal({
                type: isCardProviderTribe ? CARD_FRAME : CONFIRM_MODAL,
                props: {
                    phone: REACT_APP_ACTIVATE_CARD_PHONE_NUMBER,
                    title: translate('activateCardModal.titleText'),
                    message: translate('activateCardModal.descriptionText'),
                    handleSubmit: activate,
                },
            }),
        );
    }

    function activate() {
        dispatch(hideModal());
        dispatch(activateCard(card.cardSerial));
    }
};

export default IssuedCard;
