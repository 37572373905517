import React from 'react';
import {
    PAYMENT_MAKE_PAYMENT,
    PAYMENT_MAKE_PAYMENT_FORM,
} from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import PayeeInfiniteScrollContainer from 'components/shared/layout/payeeInfiniteScroll/containers/PayeeInfiniteScrollContainer';

const UKPayeeContainer = () => {
    return (
        <PageContainer>
            <PayeeInfiniteScrollContainer
                backRoute={PAYMENT_MAKE_PAYMENT}
                nextRoute={PAYMENT_MAKE_PAYMENT_FORM}
            />
        </PageContainer>
    );
};

export default UKPayeeContainer;
