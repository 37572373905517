import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import { useSelector } from 'react-redux';
import {
    selectPayeeRecommendedDetails,
    selectPayeeVerificationErrorInfo,
    selectUnverifiedNewPayee,
} from 'lib/slices/payeeSlice';
import Button from 'components/shared/generic/button/presentational/Button';
import { useHistory } from 'react-router-dom';
import { PAYMENT_ADD_PAYEE } from '../../../../../constants/shared/routes';
import { useCreatePayee } from '../hooks/useCreatePayee';
import PayeeCreationWrapper from '../components/PayeeCreationWrapper';
import useGetCaseConfig from 'lib/hooks/useGetCaseConfig';
import {
    CancelButton,
    ChangePayeeButton,
    ProceedAnywayButton,
} from '../components/Button';
import RecommendedPayeeContainer from './RecommendedPayeeContainer';
import useTranslate from 'lib/languages/useTranslate';

const caseButtons = [CancelButton, ChangePayeeButton, ProceedAnywayButton];

const ConfirmationOfPayeeContainer = () => {
    const history = useHistory();
    const translate = useTranslate('addPayee');

    const { type, name, accountNumber, sortCode } =
        useSelector(selectUnverifiedNewPayee) || {};
    const recommendedPayeeDetails = useSelector(selectPayeeRecommendedDetails);
    const { validationErrors, reason_code } = useSelector(
        selectPayeeVerificationErrorInfo,
    );
    const { icon: Icon, title, text, buttons } =
        useGetCaseConfig(reason_code, caseButtons) || {};

    const { handleCreatePayee } = useCreatePayee();

    const getErrorStyle = (field: string) =>
        validationErrors[field] ? 'error' : '';

    const navigateToAddPayee = () => {
        history.push(PAYMENT_ADD_PAYEE);
    };

    const onCreatePayee = () => {
        handleCreatePayee(recommendedPayeeDetails);
    };

    return (
        <PageContainer className="confirmation-of-payee-container">
            <PayeeCreationWrapper>
                <div className="confirmation-of-payee-header">
                    <img src={Icon} alt="Warning" className="warning-icon" />
                    <Typography className="title">{title}</Typography>
                </div>
                <Typography className="text">{text}</Typography>
                <div className="confirmation-of-payee-detailContainer">
                    <Typography className="detail-header-text">
                        {translate('enter')}
                    </Typography>
                    <div className="confirmation-of-payee-detail-box">
                        <Typography className="payee-title bg-white">
                            {translate('name')}
                        </Typography>
                        <Typography
                            className={`payee-name ${getErrorStyle('name')} bg-white`}
                        >
                            {name}
                        </Typography>
                        <Typography className="payee-title bg-white">
                            {translate('accountType')}
                        </Typography>
                        <Typography
                            className={`payee-type ${getErrorStyle('type')} bg-white`}
                        >
                            {type}
                        </Typography>
                        <div className="account-sort-code">
                            <div className="account-sort-code-box">
                                <Typography className="payee-title bg-white">
                                    {translate('sortCode')}
                                </Typography>
                                <Typography className="sort-code bg-white">
                                    {sortCode}
                                </Typography>
                            </div>
                            <div className="account-sort-code-line"></div>
                            <div className="account-sort-code-box">
                                <Typography className="payee-title bg-white">
                                    {translate('accountNumber')}
                                </Typography>
                                <Typography
                                    className={`account-number ${getErrorStyle(
                                        'accountNumber',
                                    )} bg-white`}
                                >
                                    {accountNumber}
                                </Typography>
                            </div>
                        </div>
                        <div className="button-apply-container">
                            <i className="fa fa-edit"></i>
                            <Button
                                className="apply-button"
                                onClick={navigateToAddPayee}
                            >
                                {translate('change')}
                            </Button>
                        </div>
                    </div>
                    {recommendedPayeeDetails && (
                        <RecommendedPayeeContainer
                            recommendedPayeeDetails={recommendedPayeeDetails}
                            onCreatePayee={onCreatePayee}
                            errorStyle={getErrorStyle('accountNumber')}
                        />
                    )}
                </div>
                <div className="confirmation-of-payee-buttonContainer">
                    {buttons?.map((Button, index) => (
                        <Button key={index} />
                    ))}
                </div>
            </PayeeCreationWrapper>
        </PageContainer>
    );
};

export default ConfirmationOfPayeeContainer;
