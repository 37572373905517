import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedPayee } from 'lib/selectors';
import { selectPayee } from 'lib/actions';
import { PAYMENT_STANDING_ORDER } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import PaymentSuccess from '../presentational/PaymentSuccess';

const PaymentSuccessContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const selectedPayee = useSelector(getSelectedPayee);

    const handleSuccessClick = () => {
        history.push(PAYMENT_STANDING_ORDER);
    };

    useEffect(() => {
        if (!selectedPayee || !location.state || !location.state.data) {
            history.push(PAYMENT_STANDING_ORDER);
        }

        return () => {
            dispatch(selectPayee(null));
        };
    }, [dispatch, history, location.state, selectedPayee]);

    return (
        <PageContainer>
            {location.state && location.state.data && (
                <PaymentSuccess
                    data={location.state.data}
                    onSuccessClick={handleSuccessClick}
                />
            )}
        </PageContainer>
    );
};

export default PaymentSuccessContainer;
