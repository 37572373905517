import React, { useState } from 'react';
import analytics from 'utils/analytics';

const Accordion = ({
    title = 'Menu Item',
    tracking,
    trackingAction = 'ACCORDION_CLICK',
    trackingLabel,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(state => !state);
        if (tracking) {
            if (isOpen) {
                analytics.sendButtonEvent(
                    trackingAction,
                    trackingLabel || title,
                );
            }
        }
    };

    return (
        <div className="sm-accordion">
            <div
                onClick={handleClick}
                className={`menu-item-container ${!isOpen || 'open'}`}
            >
                <div className="menu-item-content">
                    {title && <p className="menu-item-title">{title}</p>}
                </div>
                <div className="menu-item-arrow">
                    <i className="fa fa-caret-right" />
                </div>
            </div>
            <div className={`accordion-item ${isOpen || 'collapsed'}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    );
};

export default Accordion;
