import { useDispatch, useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import Button from 'components/shared/generic/button/presentational/Button';
import { logout } from 'lib/actions';
import { setAuthStep } from 'utils/generic';

export default function ResetButton() {
    const dispatch = useDispatch();
    const isDarkModeEnabled = useSelector(getTheme);

    const onClick = () => {
        dispatch(logout());
        setAuthStep(null)
    }

    return (
        <Button
            onClick={onClick}
            className={`reset-button ${isDarkModeEnabled ? 'dark' : ''}`}
        >
            Reset
        </Button>
    );
}
