import React from 'react';
import { useDispatch } from 'react-redux';
import AuthorizePaymentModal from '../presentational/AuthorizePaymentModal';

const AuthorizePaymentModalContainer = ({ hideModal, paymentInfo }) => {
    const dispatch = useDispatch();

    const handleClose = () => dispatch(hideModal());

    return (
        <div className="authorize-payment-modal">
            <AuthorizePaymentModal
                handleClose={handleClose}
                data={paymentInfo}
            />
        </div>
    );
};

export default AuthorizePaymentModalContainer;
