import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommunicationPreferences,
    updateCommunicationPreferences,
} from 'lib/actions';
import {
    getCommsPreferences,
    getCommsPreferencesIsFetching,
    getCommsPreferencesError,
    getCommsPreferencesIsPosting,
    getCommsPreferencesPostError,
} from 'lib/selectors';

const useCommunicationPreferences = () => {
    const dispatch = useDispatch();
    const commsPreferences = useSelector(getCommsPreferences);
    const isFetching = useSelector(getCommsPreferencesIsFetching);
    const error = useSelector(getCommsPreferencesError);
    const postError = useSelector(getCommsPreferencesPostError);
    const isPosting = useSelector(getCommsPreferencesIsPosting);
    const [comms, setComms] = useState({});

    useEffect(() => {
        dispatch(fetchCommunicationPreferences());
    }, [dispatch]);

    useEffect(() => {
        if (commsPreferences) {
            setComms(commsPreferences);
        }
    }, [commsPreferences]);

    const updateValue = (name, value) => {
        return {
            ...comms,
            [name]: value,
        };
    };

    const handleSwitchClick = ({ target }) => {
        if (isPosting) return;
        const isChecked = target.checked;
        const updatedState = updateValue(target.name, isChecked);
        setComms(updatedState);
        dispatch(updateCommunicationPreferences(updatedState)).then(state => {
            if (
                state &&
                state.type === 'UPDATE_COMMUNICATION_PEFERENCES_FAILURE'
            ) {
                setComms(updateValue(target.name, !isChecked));
            }
        });
    };

    return {
        comms,
        isFetching,
        error,
        postError,
        handleSwitchClick,
    };
};

export default useCommunicationPreferences;
