import ErrorIcon from '_content/images/icons/payment/error.png';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Checkbox from 'components/shared/form/presentational/Checkbox';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    PAYMENT_ADD_PAYEE,
    PAYMENT_MANAGE_PAYEES,
} from '../../../../../constants/shared/routes';
import { resetPayeeState, selectUnverifiedNewPayee } from 'lib/slices';
import { useCreatePayee } from '../hooks/useCreatePayee';
import PayeeCreationWrapper from '../components/PayeeCreationWrapper';
import useTranslate from 'lib/languages/useTranslate';

const ForceCreatePayeeContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const translate = useTranslate('addPayee.force');
    const unverifiedNewPayee = useSelector(selectUnverifiedNewPayee);
    const { handleCreatePayee } = useCreatePayee();
    const [checked, setChecked] = useState(false);

    const onChange = () => {
        setChecked(!checked);
    };

    const handleCancel = () => {
        history.push(PAYMENT_MANAGE_PAYEES);
        dispatch(resetPayeeState());
    };

    const navigateToAddPayee = () => {
        history.push(PAYMENT_ADD_PAYEE);
    };

    const onCreatePayee = () => {
        handleCreatePayee(unverifiedNewPayee);
    };

    return (
        <PageContainer className="force-create-payee">
            <PayeeCreationWrapper>
                <div className="header-container">
                    <img src={ErrorIcon} alt="Error" />
                    <Typography tag="h1" className="red-text header">
                        {translate('iconText')}
                    </Typography>
                    <Typography className="red-text sub-header">
                        {translate('descriptionText')}
                    </Typography>
                    <Typography className="question-text">
                        {translate('question')}
                    </Typography>
                </div>
                <div className="button-container">
                    <Button onClick={handleCancel} className="cancel-button">
                        {translate('buttonCancel')}
                    </Button>
                    <Button
                        onClick={navigateToAddPayee}
                        className="change-button"
                    >
                        {translate('buttonChange')}
                    </Button>
                </div>
                <div className="checkbox-container">
                    <Checkbox
                        onChange={onChange}
                        checked={checked}
                        text={translate('checkBox')}
                    />
                    <Button
                        onClick={onCreatePayee}
                        disabled={!checked}
                        className="proceed-button"
                    >
                        {translate('buttonProceed')}
                    </Button>
                </div>
            </PayeeCreationWrapper>
        </PageContainer>
    );
};

export default ForceCreatePayeeContainer;
