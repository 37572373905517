import ReferAFriendAd from '_content/images/transactions/refer-a-friend.png';
import CashbackRewardAd from '_content/images/transactions/cashback-rewards.png';
import MoneyMakers from '_content/images/transactions/money-makers.png';
import { SUITS_ME_MONEY_MAKERS } from 'lib/constants/routes';
import { MORE_CASHBACK_REWARDS, MORE_REFER_A_FRIEND } from './routes';

const advertsList = [
    {
        subtitle: 'Money makers',
        title: 'Money makers',
        background: MoneyMakers,
        link: SUITS_ME_MONEY_MAKERS,
        isExternal: true,
    },
    {
        subtitle: 'Sign up for',
        title: 'Suitsme Rewards',
        background: ReferAFriendAd,
        link: MORE_REFER_A_FRIEND,
        isExternal: false,
    },
    {
        subtitle: 'Second special offer',
        title: 'Special reward',
        background: CashbackRewardAd,
        link: MORE_CASHBACK_REWARDS,
        isExternal: false,
    },
];

export default advertsList;
