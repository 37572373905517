import { useDispatch, useSelector } from 'react-redux';
import {
    getIsFetchingPendingTransactions,
    getPendingTransactions,
    getTotalAmountPending,
} from 'lib/selectors';
import { useEffect } from 'react';
import { fetchPendingTransactions } from 'lib/actions';

const usePendingTransactions = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector(getIsFetchingPendingTransactions);
    const pendingTransactions = useSelector(getPendingTransactions);
    const pendingTransactionsAmount = useSelector(getTotalAmountPending);

    useEffect(() => {
        if (!isFetching) {
            setTimeout(async () => {
                dispatch(fetchPendingTransactions());
            }, 10000);
        }
    }, [isFetching, dispatch]);

    return {
        isFetching,
        pendingTransactionsAmount,
        pendingTransactions,
    };
};

export default usePendingTransactions;
