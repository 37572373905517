import React, { useEffect } from 'react';

import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { formatMoney } from 'lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFees } from 'lib/selectors';
import { fetchFees } from 'lib/actions';

const LSDStatusCardModal = ({ handleSubmit, handleClose }) => {
    const dispatch = useDispatch();

    const translate = useTranslate('cards.orderReplacementModal');

    const allFees = useSelector(getAllFees);

    useEffect(() => {
        dispatch(fetchFees());
    }, [dispatch]);

    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description">
                <Typography tag="h1">{translate('titleText')}</Typography>
                <Typography styles={{ marginBottom: '8px' }}>
                    {translate('feeWarning', {
                        fee: formatMoney(allFees.replacementCard),
                    })}
                </Typography>
            </div>
            <div className="button-container">
                <Button className="cancel" onClick={handleClose}>
                    {translate('cancel')}
                </Button>
                <Button onClick={handleSubmit}>
                    {translate('submitText')}
                </Button>
            </div>
        </ModalOuterContainer>
    );
};

export default LSDStatusCardModal;
