import React from 'react';

import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CurrencyModalItem from 'components/shared/layout/currencyModalItem/presentational/CurrencyModalItem';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const SearchAndListModal = ({
    handleCloseModal,
    handleClick,
    searchTerm,
    handleSearchTerm,
    result,
    popularList,
    othersList,
}) => {
    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description search-and-list">
                <SectionTitle title="Choose Currency" />
                <div className="search-input-container">
                    <i className="fas fa-search" />
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearchTerm}
                        value={searchTerm}
                    />
                </div>
                <div className="search-and-list-container">
                    {!searchTerm ? (
                        <>
                            <div className="search-list-container">
                                <Typography tag="h3">Popular</Typography>
                                <div>
                                    {popularList.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(item)}
                                            className="search-and-list-item-container"
                                        >
                                            <CurrencyModalItem item={item} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="search-list-container">
                                <Typography tag="h3">Others</Typography>
                                <div>
                                    {othersList.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(item)}
                                            className="search-and-list-item-container"
                                        >
                                            <CurrencyModalItem item={item} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="search-list-container">
                            <Typography tag="h3">Result</Typography>
                            {searchTerm && result.length ? (
                                <div>
                                    {result.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(item)}
                                            className="search-and-list-item-container"
                                        >
                                            <CurrencyModalItem item={item} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <Typography className="results-errors">
                                    No results could be found
                                </Typography>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ButtonContainer>
                <Button className="cancel" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default SearchAndListModal;
