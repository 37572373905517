import { showModal, hideModal } from 'actions/modal';

export const dispatchShowModal = (dispatch, modalName, modalProps = {}) => {
    dispatch(
        showModal({
            type: modalName,
            props: { ...modalProps },
        }),
    );
};

export const dispatchHideModal = dispatch => {
    dispatch(hideModal());
};
