import { useDispatch } from 'react-redux';
import CardFrameModal from '../presentational/CardFrameModal';
import { hideModal } from 'actions/modal';
const CardFrameModalContainer = () => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideModal());
    }

    return <CardFrameModal handleClose={handleClose} />;
};

export default CardFrameModalContainer;
