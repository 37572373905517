import { useEffect, FC, useCallback, useState } from 'react';
import { Check } from '../presentational/Check';
import { useParams, useHistory } from 'react-router-dom';
import { handleConfirmSignIn, handleCurrentSession } from 'lib/utils/amplify';
import { setJwtHeader } from 'lib/utils';
import { setAuthStep } from "utils/generic";
import { AuthStep } from 'constants/shared/enums';
import { AUTH_OTP, AUTH_EMAIL, LOGIN,AUTH_ACTIVATION_SUCCESS } from '../../../../constants/shared/routes';
import { ErrorName } from 'lib/type/enums'
import ResetButton from 'components/shared/layout/resetButton/ResetButton';

interface Params {
    id: string;
}

interface SessionResult {
    accessToken: string;
    idToken: string;
    err: unknown;
}

export const CheckContainer: FC = () => {
    const history = useHistory();
    const { id } = useParams<Params>();
    const [error, setError] = useState<{ message: string, name: string } | null>(null);

    const confirmSignIn = useCallback(
        async code => {
            try {
                await handleConfirmSignIn(code);
                const result = (await handleCurrentSession()) as SessionResult;
                localStorage.setItem('cognitoToken', result.accessToken);
                setJwtHeader(result.accessToken);
                const previousAuthStep = localStorage.getItem('authStep') as AuthStep;
                if (previousAuthStep === AuthStep.EMAIL_ACTIVATION_FLOW) {
                    return history.push(AUTH_ACTIVATION_SUCCESS);
                }
                history.push(AUTH_OTP);
                setAuthStep(AuthStep.OTP);
            } catch (e) {
                const error = e as { message: string, name: string };
                setError(error);
            }
        },
        [history],
    );

    const handleTryAgain = () => {
        if (error?.name !== ErrorName.INCORRECT_CODE) {
            setAuthStep(null);
            history.push(LOGIN);
            return;
        }
        history.push(AUTH_EMAIL);
        setError(null);
    };

    useEffect(() => {
        confirmSignIn(id);
    }, [id, confirmSignIn]);

    return <>
        <Check error={error?.message} handleTryAgain={handleTryAgain} />
        <ResetButton />
    </>;
};
