import React from 'react';
import { accountRoles, subscriptionMakeBalancesLookup } from 'lib/constants';
import useTranslate from 'lib/languages/useTranslate';

import Button from 'components/shared/generic/button/presentational/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'actions/modal';
import {
    EXCESSIVE_FUNDS_MODAL,
    REGRADE_SUBSCRIPTION_MODAL,
} from 'constants/shared/modalTypes';
import { getAccount } from 'lib/selectors';
import { useRegradeSubscription } from 'lib/hooks';

const roleNames = {
    [accountRoles.Essential]: 'essential',
    [accountRoles.Premium]: 'premium',
    [accountRoles.PremiumPlus]: 'premiumPlus',
};

const SubscriptionCard = ({ role, productType, isCurrentSubscription }) => {
    const dispatch = useDispatch();

    const roleName = roleNames[role.productClassCode];

    const translate = useTranslate('pricePlan');
    const { availableBalance } = useSelector(getAccount);
    const maxAmountAllowed = subscriptionMakeBalancesLookup[productType];

    const { isUpgrade, isDowngradeToEssential } = useRegradeSubscription(
        productType,
        role.productClassCode,
    );

    return (
        <div className="subscription-item" key={role}>
            <h2 className="title">{role.title}</h2>
            <div className="benefits-list">
                {role.perks.map((benefit, i) => {
                    return (
                        <span className="benefit" key={i}>
                            <p className="benefit-title">{benefit.name}</p>
                            <p className="benefit-text">
                                {benefit.description}
                            </p>
                        </span>
                    );
                })}
            </div>

            {role.productClassCode !== accountRoles.Essential ||
            (role.productClassCode === accountRoles.Essential &&
                isCurrentSubscription) ? (
                <Button
                    disabled={
                        productType === role.productClassCode ||
                        isDowngradeToEssential
                    }
                    onClick={e => {
                        if (availableBalance > maxAmountAllowed) {
                            showExcessiveFundsModal(e);
                        } else {
                            showConfirmModal(e);
                        }
                    }}
                    tracking
                    trackingLabel={`${roleName}_SUBSCRIPTION_CLICK`}
                >
                    {translate(
                        productType === role.productClassCode
                            ? 'currentText'
                            : isUpgrade
                            ? 'upgrade'
                            : 'downgrade',
                    )}
                </Button>
            ) : (
                <p className="downgrade-text">
                    {translate('customerServiceText')}
                </p>
            )}
        </div>
    );

    function showConfirmModal() {
        dispatch(
            showModal({
                type: REGRADE_SUBSCRIPTION_MODAL,
                props: {
                    currentRole: productType,
                    newRole: role.productClassCode,
                },
            }),
        );
    }

    function showExcessiveFundsModal() {
        dispatch(
            showModal({
                type: EXCESSIVE_FUNDS_MODAL,
            }),
        );
    }
};

export default SubscriptionCard;
