import { useLocation } from 'react-router-dom';
import { rootStack } from '../index';

function useIsPrivateRoute() {
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase();

    const publicRoutes = rootStack
        .filter(s => !s.auth)
        .map(s => s.base.toLowerCase());

    return path === '/' || publicRoutes.every(route => !path.startsWith(route));
}

export default useIsPrivateRoute;
