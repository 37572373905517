import { useState } from 'react';

import { useCheckAccountStatus } from 'lib/hooks';

const useAccountDormantModal = () => {
    const [isDormant] = useCheckAccountStatus();
    const [showDormantModal, setShowDormantModal] = useState(isDormant);

    function handleClose() {
        setShowDormantModal(false);
    }

    return [showDormantModal, handleClose];
};

export default useAccountDormantModal;
