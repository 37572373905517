import React from 'react';
import dayjs from 'dayjs';
import TransactionsCard from './TransactionsCard';
import PendingTransactionCard from './PendingTransactionCard';
import AccountBalanceCard from './AccountBalanceCard';

const Transactions = ({ data }) => {
    return (
        <div className="monthly-transaction-container">
            {data.map((item, index) => {
                if (item.type === 'sectionHeader') {
                    return <h2 key={index}>{item.text}</h2>;
                } else if (item.type === 'accountBalance') {
                    return <AccountBalanceCard key={index} item={item} />;
                } else if (item.type === 'pending') {
                    return <PendingTransactionCard key={index} item={item} />;
                } else {
                    return (
                        <TransactionsCard
                            key={index}
                            item={item}
                            index={index}
                            keyIndex={`${dayjs(
                                item.createdDate,
                            ).format()}-${index}`}
                        />
                    );
                }
            })}
        </div>
    );
};

export default Transactions;
