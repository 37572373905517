import { createReducer } from '@reduxjs/toolkit';
import { logoutSuccess } from 'lib/actions';
import { setJwtHeader } from 'lib/utils';
import { clearRedirectRoute } from 'actions/redirect';

const initialState = {
    route: null,
    isPosting: false,
};

export default createReducer(initialState, {
    [logoutSuccess]: handleLogoutSuccess,
    [clearRedirectRoute]: handleClear,
});

function handleLogoutSuccess(state) {
    setJwtHeader(null);
    localStorage.removeItem('accessToken') ??
        localStorage.removeItem('cognitoToken');
    state.route = '/auth/login';
    state.isPosting = false;
}

function handleClear(state) {
    state.route = null;
}
