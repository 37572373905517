import React from 'react';
import dayjs from 'dayjs';
import { formattedCardNumber } from 'lib/utils';

const NormalCard = ({ info, isDarkText = false }) => {
    const cardNumber = formattedCardNumber(info.cardPan);

    return (
        <div className={`content normal-card ${isDarkText ? 'dark-text' : ''}`}>
            <p className="expiry-date">
                {dayjs(info.expiryDate).format('MM/YY')}
            </p>
            <span className={'card-number'}>{cardNumber}</span>
            <p className="name">{info.cardHolderName}</p>
        </div>
    );
};

export default NormalCard;
