import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatMoney } from 'lib/utils';
import { standingOrdersRecurringNames } from 'lib/constants';

const StandingOrderItem = ({ item }) => {
    const history = useHistory();

    return (
        <div
            className="menu-item-container"
            onClick={() => history.push(`/payments/standing-order/${item.id}`)}
        >
            <div className="menu-item-left">
                <h2 className="menu-item-left-title">{item.payeeName}</h2>
                <h3 className="menu-item-left-date">
                    {standingOrdersRecurringNames[item.recurType]}
                </h3>
            </div>
            <div className="menu-item-right">
                <h2 className="menu-item-right-amount">
                    £{formatMoney(item.amount)}
                </h2>
                <div className="menu-item-arrow">
                    <i className="fa fa-caret-right" />
                </div>
            </div>
        </div>
    );
};

export default StandingOrderItem;
