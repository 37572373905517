import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'actions/modal';
import ModalOuter from '../presentational/ModalOuter';
import { isEmpty } from 'lib/utils';
import { clearFormError, showFieldErrors } from 'lib/actions';
import { getFieldErrors } from 'lib/selectors';
import { useDispatch, useSelector } from 'react-redux';
const ModalOuterContainer = props => {
    const {
        children,
        hideModal,
        className,
        handleSubmit,
        isPosting,
        customButtons = false,
        checkFormErrors,
    } = props;

    const fieldErrors = useSelector(getFieldErrors);

    const dispatch = useDispatch();

    return (
        <ModalOuter
            className={`modal-outer ${className}`}
            handleClose={hideModal}
            handleSubmit={_handleSubmit}
            customButtons={customButtons}
            isPosting={isPosting}
        >
            {children}
        </ModalOuter>
    );

    function _handleSubmit(e) {
        e.preventDefault();

        if (checkFormErrors) {
            if (!isEmpty(fieldErrors)) {
                dispatch(showFieldErrors());
            } else if (!isPosting) {
                dispatch(clearFormError());
                handleSubmit();
            }
        } else {
            handleSubmit();
        }
    }
};

const mapDispatchToProps = { hideModal };

export default connect(null, mapDispatchToProps)(ModalOuterContainer);
