import Button from 'components/shared/generic/button/presentational/Button';
import { useHistory } from 'react-router-dom';

import {
    PAYMENT_ADD_PAYEE,
    PAYMENT_FORCE_ADD_PAYEE,
    PAYMENT,
} from '../../../../../constants/shared/routes';
import { useDispatch } from 'react-redux';
import { resetPayeeState } from 'lib/slices';
import useTranslate from 'lib/languages/useTranslate';

const CancelButton: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const translate = useTranslate('addPayee.force');

    return (
        <Button
            onClick={() => {
                history.push(PAYMENT);
                dispatch(resetPayeeState());
            }}
            className="cancel-button"
        >
            {translate("buttonCancel")}
        </Button>
    );
};

const ChangePayeeButton: React.FC = () => {
    const history = useHistory();
    const translate = useTranslate('addPayee.force');
    return (
        <Button
            onClick={() => history.push(PAYMENT_ADD_PAYEE)}
            className="change-button"
        >
            {translate("buttonChange")}
        </Button>
    );
};

const ProceedAnywayButton: React.FC = () => {
    const history = useHistory();
    const translate = useTranslate('addPayee.force');
    return (
        <Button
            onClick={() => history.push(PAYMENT_FORCE_ADD_PAYEE)}
            className="proceed-button"
        >
            {translate("buttonProceed")}
        </Button>
    );
};

export { CancelButton, ChangePayeeButton, ProceedAnywayButton };
