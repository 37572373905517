import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

import { replaceSpecialChars } from 'lib/utils/generic';

const TextInput = ({
    type = 'text',
    name,
    placeholder,
    classes,
    value,
    onChange,
    charLimit,
    minLength,
    maxNum,
    disabled,
    showError,
    appendIcon,
    prependIcon,
    ref,
    shouldReplaceSpecialCharacters,
}) => {
    const _ref = ref ? { ref } : {};
    return (
        <div {..._ref} className={`sm-input ${disabled ? 'disabled' : ''}`}>
            {prependIcon && (
                <span className="sm-input-prepend-icon">{appendIcon}</span>
            )}
            {appendIcon && (
                <span className="sm-input-append-icon">{appendIcon}</span>
            )}
            <input
                className={classes}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value || ''}
                onChange={newVal => {
                    let updatedVal = newVal.target.value;
                    if (shouldReplaceSpecialCharacters) {
                        updatedVal = replaceSpecialChars(updatedVal);
                    }
                    onChange(name, updatedVal);
                }}
                onBlur={showError}
                minLength={minLength}
                maxLength={charLimit}
                max={maxNum}
                disabled={disabled}
            />
        </div>
    );
};

export default withFieldValidation(TextInput);
