import { FC } from 'react';
import { formatSortCode } from 'lib/utils';
import dayjs from 'dayjs';

import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import useTranslate from 'lib/languages/useTranslate';

interface Data {
    amount: number;
    recipientName: string;
    recipientAccountNumber: string;
    recipientSortCode: string;
    reference: string;
}

interface AuthorizePaymentModalProps {
    handleClose: () => void;
    data: Data;
}

const AuthorizePaymentModal: FC<AuthorizePaymentModalProps> = ({
    handleClose,
    data,
}) => {
    const translate = useTranslate('makePayment');

    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description authorize-payment">
                <p>{translate('sendAndConfirm.line1')}</p>
                <p>
                    <strong>£{data.amount}</strong>
                </p>
                <p>{translate('sendAndConfirm.line2')}</p>
                <p>
                    <strong>{data.recipientName}</strong>
                </p>
                <p>
                    <strong>
                        {translate('sendAndConfirm.line3')}{' '}
                        {data.recipientAccountNumber}
                    </strong>
                </p>
                <p>
                    <strong>{formatSortCode(data.recipientSortCode)}</strong>
                </p>
                <p>
                    <strong>{dayjs().format('MM/DD/YYYY')}</strong>
                </p>
                <p>{translate('sendAndConfirm.line4')}</p>
                <p>
                    <strong>{data.reference}</strong>
                </p>
                <p>{translate('sendAndConfirm.line5')}</p>
            </div>
            <ButtonContainer>
                <Button className="cancel" onClick={handleClose}>
                    {translate('sendAndConfirm.cancel')}
                </Button>
                <Button className="confirm">
                    {translate('sendAndConfirm.confirmAndSend')}
                </Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default AuthorizePaymentModal;
