import Button from 'components/shared/generic/button/presentational/Button';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';

const PaymentSuccess = ({ onSuccessClick, data }) => {
    const isDarkModeEnabled = useSelector(getTheme);
    return (
        <div className="payment-status-container full-width">
            <div className="status-icon">
                <i className="fas fa-check-circle"></i>
            </div>
            <div className={`status-message ${isDarkModeEnabled ? 'text-white' : 'text-dark'}`}>
                You have setup a standing order to {data.recipient}
            </div>
            <Button onClick={onSuccessClick}>Return to payments</Button>
        </div>
    );
};

export default PaymentSuccess;
