import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPayees, selectPayee } from 'lib/actions';
import { getPayees, getLastPayeePage } from 'lib/selectors';
import { PAYMENT_ADD_PAYEE } from 'constants/shared/routes';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import PayeeInfiniteScroll from '../presentational/PayeeInfiniteScroll';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import useTranslate from 'lib/languages/useTranslate';

const PayeeInfiniteScrollContainer = ({ backRoute, nextRoute }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const last = useSelector(getLastPayeePage);
    const payee = useSelector(getPayees);
    const translate = useTranslate('managePayees');

    const handleAddNewPayee = () => {
        history.push(PAYMENT_ADD_PAYEE, { backRoute: backRoute });
    };

    const handleSelectedPayee = reference => {
        dispatch(selectPayee(reference));
        history.push(nextRoute);
    };

    const handleFetchPage = page => {
        dispatch(fetchPayees(page));
    };

    return (
        <>
            <DataCheck noDataMessage={translate('noDataMessage')} data={payee}>
                <PayeeInfiniteScroll
                    data={payee}
                    last={last}
                    onPayeeClick={handleSelectedPayee}
                    handleFetchPage={handleFetchPage}
                />
            </DataCheck>
            <ButtonContainer>
                <Button onClick={handleAddNewPayee} className="center">
                    {translate('addNewPayee')}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default PayeeInfiniteScrollContainer;
