import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';

export const Activated = ({ onNext }) => {
    const translate = useTranslate("accountActivated");
    const isDarkModeEnabled = useSelector(getTheme);
    return (
        <div className="login-container">
            <Typography tag="h1">{translate('titleText')}</Typography>
            <Typography tag="p" className={isDarkModeEnabled ? 'text-white' : 'text-dark'}>
                {translate('descriptionText')}
            </Typography>
            <Button onClick={onNext}>{translate('continue')}</Button>
        </div>
    );
};
