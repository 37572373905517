import React from 'react';
import ModalOuter from 'components/shared/modals/generic/presentational/ModalOuter';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const AccountStatusModal = ({ handleClose }) => {
    const translate = useTranslate('dormantModal');

    return (
        <ModalOuter customButtons>
            <SectionTitle title={translate('titleText')} />
            <div className="description">
                <Typography tag="p">{translate('infoText')}</Typography>
            </div>

            <ButtonContainer className="center">
                <Button onClick={handleClose}>{translate('ok')}</Button>
            </ButtonContainer>
        </ModalOuter>
    );
};

export default AccountStatusModal;
