import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPayees } from 'lib/actions';
import { getPayees, getPayeesIsFetching, getPayeesError } from 'lib/selectors';
import { PAYMENT } from 'constants/shared/routes';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import MakePayment from '../presentational/MakePayment';
import useTranslate from 'lib/languages/useTranslate';

const MakePaymentContainer = () => {
    const dispatch = useDispatch();
    const payee = useSelector(getPayees);
    const isFetching = useSelector(getPayeesIsFetching);
    const error = useSelector(getPayeesError);
    const [active, setActive] = useState(false);
    const translate = useTranslate('managePayees');
    
    const ukClick = useCallback(() => {
        setActive(false);
    }, [setActive]);

    const intClick = useCallback(() => {
        setActive(true);
    }, [setActive]);

    useEffect(() => {
        dispatch(fetchPayees(1));
    }, [dispatch]);

    return (
        <PageContainer className="manage-payee-container">
            <SectionTitle title={translate('title')} backRoute={PAYMENT} />
            <DataCheck
                data={payee}
                isLoading={isFetching}
                error={error}
                renderIfNotEmpty
                noDataMessage={translate('noDataMessage')}
            >
                <MakePayment
                    active={active}
                    ukClick={ukClick}
                    intClick={intClick}
                />
            </DataCheck>
        </PageContainer>
    );
};

export default MakePaymentContainer;
