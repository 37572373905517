import React from 'react';
import { Redirect } from 'react-router-dom';
import { validateJWT } from 'lib/utils';
import { useDispatch } from 'react-redux';
import { handleSignOut } from 'lib/utils/amplify';
import { logout } from 'lib/actions';

const WithAuth = ProtectedComponent => {
    const isAuthorized = validateJWT(localStorage.getItem('accessToken'));
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        handleSignOut();
    };

    if (!isAuthorized) {
        handleLogout();
        return <Redirect to="/auth" />;
    }

    return ProtectedComponent.children;
};

export default WithAuth;
