import Typography from 'components/shared/generic/typography/presentational/Typography';
import LoadingIcon from '_content/images/icons/more/loading.png';
import SuccessIcon from '_content/images/icons/more/success.png';
import { getTheme } from 'selectors/darkMode';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import useTranslate from 'lib/languages/useTranslate';

interface IGenerateText {
    headerText: string;
    text: string;
    icon: string;
}

interface CopCheckModalProps {
    isAccountV2: boolean;
    isPayeeCreated: boolean;
}

const CopCheckModal = ({ isAccountV2, isPayeeCreated }: CopCheckModalProps) => {
    const translate = useTranslate('addPayee');
    const isDarkModeEnabled = useSelector(getTheme);

    const generatePayeeModalText = useMemo((): IGenerateText => {
        const accountTypeHeaderText = isAccountV2
            ? translate('checking')
            : translate('saving');
        const accountMatchSubText = isAccountV2
            ? translate('match')
            : translate('saved');

        const finalHeaderText = !isPayeeCreated
            ? accountTypeHeaderText
            : translate('success');
        const finalSubText = !isPayeeCreated
            ? translate('pleaseWait')
            : accountMatchSubText;
        const statusIcon = !isPayeeCreated ? LoadingIcon : SuccessIcon;

        return {
            headerText: finalHeaderText,
            text: finalSubText,
            icon: statusIcon,
        };
    }, [isAccountV2, isPayeeCreated, translate]);

    const { headerText, text, icon: Icon } = generatePayeeModalText;

    return (
        <div className="login-container checking-container d-flex-col-center">
            <div className="checking-box">
                <div
                    className={`checking-content ${
                        isDarkModeEnabled ? 'dark-mode' : ''
                    }`}
                >
                    <Typography tag="h1">{headerText}</Typography>
                    <img
                        alt="loading"
                        src={Icon}
                        className={`mb-2 ${isPayeeCreated && 'imageSuccess'}`}
                    />
                    <Typography tag="p">{text}</Typography>
                </div>
            </div>
        </div>
    );
};

export default CopCheckModal;
