import React from 'react';
import { connect } from 'react-redux';

import {
    ERROR_MODAL,
    SUCCESS_MODAL,
    ENTER_CVC_MODAL,
    CARD_FRAME,
    STATUS_CARD_MODAL,
    LSD_STATUS_CARD_MODAL,
    ADD_NEW_CARDHOLDER,
    AUTHORIZE_PAYMENT_MODAL,
    SEARCH_AND_LIST_MODAL,
    PREMIUM_ACCOUNT_MODAL,
    CONFIRM_MODAL,
    CONFIRM_AND_SEND_MODAL,
    REGRADE_SUBSCRIPTION_MODAL,
    EXCESSIVE_FUNDS_MODAL,
    EXPORT_STATEMENT_MODAL,
    ACCOUNT_SELECT,
    DISMISS_MODAL,
    ADD_CARDHOLDER_DECLARATION,
    ADD_CARDHOLDER_FEE,
    REPLACEMENT_CARD_FEE,
    PAYMENT_FEE_MODAL,
    STANDING_ORDER_FEE_MODAL,
    GENERIC_5XX_ERROR_MODAL,
    ACCOUNT_BLOCKED_MODAL,
    DOWNLOAD_APP
} from 'constants/shared/modalTypes';
import { hideModal, showModal } from 'actions/modal';

import ErrorModal from '../presentational/ErrorModal';
import SuccessModal from '../presentational/SuccessModal';
import PremiumAccountModal from '../presentational/PremiumAccountModal';
import EnterCVCModalContainer from '../../enterCVCModal/containers/EnterCVCModalContainer';
import CardFrameModalContainer from '../../cardFrameModal/containers/CardFrameModalContainer';
import AddCardHolderModalContainer from '../../addCardHolderModal/containers/AddCardHolderModalContainer';
import StatusCardModalContainer from '../../statusCardModal/containers/StatusCardModalContainer';
import LSDStatusCardModalContainer from '../../lsdStatusCardModal/containers/LSDStatusCardModalContainer';
import AuthorizePaymentModalContainer from '../../authorizePaymentModal/containers/AuthorizePaymentModalContainer';
import SearchAndListModalContainer from '../../searchAndList/containers/SearchAndListModalContainer';
import ConfirmAndSendModalContainer from '../../confirmAndSendModal/containers/ConfirmAndSendModalContainer';
import CardHolderDeclarationModal from '../../cardholderDeclarationModal/CardHolderDeclarationModal';

import { getModalType, getModalProps } from 'selectors/modal';
import ConfirmModal from '../presentational/ConfirmModal';
import RegradeSubscriptionModal from 'components/shared/modals/generic/presentational/RegradeSubscriptionModal';
import ExcessiveFundsModal from 'components/shared/modals/generic/presentational/ExcessiveFundsModal';
import ExportStatementModal from '../../exportStatementModal/ExportStatementModal';
import AccountSelectModal from '../../accountSelect/AccountSelectModal';
import DismissModal from '../presentational/DismissModal';
import AddCardHolderFeeModal from '../../addCardHolderFeeModal/AddCardHolderFeeModal';
import ReplacementCardFeeModal from '../../replacementCardFeeModal/ReplacementCardFeeModal';
import PaymentFeeModal from '../../paymentFeeModal/PaymentFeeModal';
import StandingOrderFeeModal from '../../standingOrderFeeModal/StandingOrderFeeModal';
import GenericErrorModal from '../presentational/GenericErrorModal';
import AccountBlockedModal from '../../accountBlockedModal/AccountBlockedModal';
import DownloadAppModal from '../../downloadAppModal/DownloadAppModal'

const MODAL_COMPONENTS = {
    [ERROR_MODAL]: ErrorModal,
    [SUCCESS_MODAL]: SuccessModal,
    [CONFIRM_MODAL]: ConfirmModal,
    [DISMISS_MODAL]: DismissModal,
    [PREMIUM_ACCOUNT_MODAL]: PremiumAccountModal,
    [ENTER_CVC_MODAL]: EnterCVCModalContainer,
    [CARD_FRAME]: CardFrameModalContainer,
    [STATUS_CARD_MODAL]: StatusCardModalContainer,
    [LSD_STATUS_CARD_MODAL]: LSDStatusCardModalContainer,
    [ADD_NEW_CARDHOLDER]: AddCardHolderModalContainer,
    [AUTHORIZE_PAYMENT_MODAL]: AuthorizePaymentModalContainer,
    [SEARCH_AND_LIST_MODAL]: SearchAndListModalContainer,
    [CONFIRM_AND_SEND_MODAL]: ConfirmAndSendModalContainer,
    [REGRADE_SUBSCRIPTION_MODAL]: RegradeSubscriptionModal,
    [EXCESSIVE_FUNDS_MODAL]: ExcessiveFundsModal,
    [EXPORT_STATEMENT_MODAL]: ExportStatementModal,
    [ACCOUNT_SELECT]: AccountSelectModal,
    [ADD_CARDHOLDER_DECLARATION]: CardHolderDeclarationModal,
    [ADD_CARDHOLDER_FEE]: AddCardHolderFeeModal,
    [REPLACEMENT_CARD_FEE]: ReplacementCardFeeModal,
    [PAYMENT_FEE_MODAL]: PaymentFeeModal,
    [STANDING_ORDER_FEE_MODAL]: StandingOrderFeeModal,
    [GENERIC_5XX_ERROR_MODAL]: GenericErrorModal,
    [ACCOUNT_BLOCKED_MODAL]: AccountBlockedModal,
    [DOWNLOAD_APP]: DownloadAppModal
};

const ModalRoot = ({ modalType, modalProps, ...otherProps }) => {
    if (!modalType) return null;

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return <SpecificModal {...modalProps} {...otherProps} />;
};

const mapStateToProps = state => ({
    modalType: getModalType(state),
    modalProps: getModalProps(state),
});

const mapDispatchToProps = { hideModal, showModal };

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
