import Component from "utils/component";

interface IProps {
  tag?: string;
  className?: string;
  children: string;
  styles?: Object;
}

const Typography = ({
  tag = "p",
  className = "",
  styles = {},
  children,
  ...props
}: IProps) => {
  return (
    <Component
      as={tag}
      {...props}
      className={`sm-typography ${className}`}
      style={styles}
    >
      {children}
    </Component>
  );
};

export default Typography;
