import {
  useValidateOtpMutation,
  useSendOtpMutation,
} from "lib/services/otp";
import { Otp } from "../presentational/Otp";
import { useForm, } from "lib/utils";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useCountdownTimer from "lib/hooks/useCountdownTimer";
import { AUTH_ACTIVATION } from "../../../../constants/shared/routes";
import { AuthStep } from "constants/shared/enums";
import { setAuthStep } from "utils/generic";
import jwt_decode from 'jwt-decode';
import { finalizeAuthAndSetToken } from "utils/auth";
import ResetButton from 'components/shared/layout/resetButton/ResetButton';
import { useDispatch } from "react-redux";
import {
  updateUrlAndVersion,
} from 'lib/actions';

export const OtpContainer = () => {
  const history = useHistory();
  const authStep = localStorage.getItem('authStep') as AuthStep;
  const [form, handleChange] = useForm({ otp: "" });
  const [sendOtp, sendOtpResult] = useSendOtpMutation();
  const [validateOtp, validateOtpResult] = useValidateOtpMutation();
  const dispatch = useDispatch();
  const {
    remainingSeconds,
    startCountdown,
    isCountdownActive,
  } = useCountdownTimer(30);
  const isLoading = sendOtpResult.isLoading || validateOtpResult.isLoading;
  const error = (sendOtpResult.error || validateOtpResult.error) as {
    status: number;
    data: string;
  } | null;

  useEffect(() => {
    if(authStep === AuthStep.OTP) {
      sendOtp();
    }
  }, [sendOtp, authStep]);

  useEffect(() => {
    if (validateOtpResult.isSuccess) {
      const cognitoToken = localStorage.getItem('cognitoToken') as string;
      const userData = jwt_decode(cognitoToken)
      if (userData.accountIdV2) {
        dispatch(updateUrlAndVersion('/v2'));
      } else {
        dispatch(updateUrlAndVersion('/v1'));
      }
      if (userData.isActive) {
        finalizeAuthAndSetToken(cognitoToken);
        history.push('/');
      } else {
        history.push(AUTH_ACTIVATION);
        setAuthStep(AuthStep.ACTIVATION);
      }
    }
  }, [validateOtpResult.isSuccess, history, dispatch]);

  return (
    <>
      <Otp
        otp={form.otp}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleResendOTP={handleResendOTP}
        isPostingOTP={isLoading}
        error={error?.data}
        remainingSeconds={remainingSeconds}
        isCountdownActive={isCountdownActive}
      />
      <ResetButton/>
    </>
  );

  async function handleSubmit() {
    const otp = form.otp;
    validateOtp({ otp });
  }

  function handleResendOTP() {
    if (!isCountdownActive) {
      sendOtp();
      startCountdown();
    }
  }
};
