import Box from '../box/presentational/Box';
import Typography from '../typography/presentational/Typography';

interface IProps {
    text: string;
}

const LoadingText = ({ text }: IProps) => {
    return (
        <Box className="data-check-loading loading-spinner">
            <i className="far fa-spinner fa-spin" />
            <Typography>
                {text}
            </Typography>
        </Box>
    );
};

export default LoadingText;