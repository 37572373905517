import React from 'react';

const CurrencyModalItem = ({ item }) => {
    return (
        <div className="item-area">
            <div className="item-left">
                <div
                    className={`item-avatar currency-flag currency-flag-${item.currency_code.toLowerCase()}`}
                />
                <div className="item-name">{item.name}</div>
            </div>
            <div className="item-right">
                <div className="item-desc">{item.currency_code}</div>
            </div>
        </div>
    );
};

export default CurrencyModalItem;
