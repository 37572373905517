import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';

const PaymentSuccess = ({ onSuccessClick, data }) => {
    const translate = useTranslate('makePayment');

    if (data && data.error) {
        return (
            <div className="payment-status-container full-width">
                <div className="status-icon error">
                    <i className="fas fa-times-circle"></i>
                </div>
                <div className="status-message">
                    <Typography styles={{ marginBottom: '8px' }}>
                        {translate('error.sendPayment')}
                    </Typography>
                    <Typography>{data.error}</Typography>
                </div>
                <Button onClick={onSuccessClick}>
                    {translate('ukPaymentSuccess.return')}
                </Button>
            </div>
        );
    }

    return (
        <div className="payment-status-container full-width">
            <div className="status-icon">
                <i className="fas fa-check-circle"></i>
            </div>
            <div className="status-message">
                <Typography>
                    {translate('ukPaymentSuccess.line1')} £
                    {Number(data.amount).toFixed(2)}{' '}
                    {translate('ukPaymentSuccess.line2')} {data.recipient}
                </Typography>
            </div>
            <Button onClick={onSuccessClick}>
                {translate('ukPaymentSuccess.return')}
            </Button>
        </div>
    );
};

export default PaymentSuccess;
