import React from 'react';

import { EMAIL_REGEXP } from 'lib/constants';
import countries from 'lib/constants/countries';
import Field from 'components/shared/form/presentational/Field';
import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Form from 'components/shared/form/presentational/Form';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Select from 'components/shared/form/presentational/Select';
import TextInput from 'components/shared/form/presentational/TextInput';
import DatePicker from 'components/shared/form/presentational/DatePicker';
import useTranslate from 'lib/languages/useTranslate';
import PostCodeAutoComplete from 'components/shared/form/presentational/PostCodeAutoComplete';

const titleOptions = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Sir', value: 'Sir' },
    { label: 'Lady', value: 'Lady' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Mx', value: 'Mx' },
];

const countryOptions = countries.map(country => ({
    label: country,
    value: country,
}));

const AddCardHolderModal = ({
    handleChange,
    handleSubmit,
    isPosting,
    ...form
}) => {
    const translate = useTranslate('cards.addCardholderForm');
    return (
        <ModalOuterContainer
            className="add-cardholder-modal"
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            checkFormErrors
        >
            <div className="description">
                <Typography tag="h1">{translate('titleText')}</Typography>
            </div>

            <Form
                onSubmit={handleSubmit}
                omitButtons
                isPosting={isPosting}
                tracking
                trackingLabel="ADD_CARDHOLDER_MODAL_SUBMIT"
            >
                <Field name="title" hideLabel required>
                    <Select
                        placeholder={translate('title')}
                        required
                        name="title"
                        value={form.title}
                        options={titleOptions}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field name="forename" hideLabel required>
                    <TextInput
                        required
                        name="forename"
                        placeholder={translate('forename')}
                        value={form.forename}
                        onChange={handleChange}
                        classes="generic-input"
                        shouldReplaceSpecialCharacters
                    />
                </Field>
                <Field name="surname" hideLabel required>
                    <TextInput
                        required
                        name="surname"
                        placeholder={translate('surname')}
                        value={form.surname}
                        onChange={handleChange}
                        classes="generic-input"
                        shouldReplaceSpecialCharacters
                    />
                </Field>
                <Field name="email" hideLabel>
                    <TextInput
                        required
                        name="email"
                        placeholder={translate('email')}
                        value={form.email}
                        onChange={handleChange}
                        classes="generic-input"
                        validationRegexp={EMAIL_REGEXP}
                    />
                </Field>
                <Field name="dob" hideLabel required>
                    <DatePicker
                        required
                        name="dob"
                        placeholder={translate('dob')}
                        value={form.dob}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field name={translate('selectAddress')} required>
                    <PostCodeAutoComplete
                        value={form.postCode}
                        onChange={handleChange}
                        classes="generic-input mb"
                    />
                </Field>
                <Field name="addressLine1" hideLabel required>
                    <TextInput
                        required
                        name="addressLine1"
                        placeholder={translate('addressLine1')}
                        value={form.addressLine1}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field name="addressLine2" hideLabel>
                    <TextInput
                        name="addressLine2"
                        placeholder={translate('addressLine2')}
                        value={form.addressLine2}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field>
                    <TextInput
                        name="addressLine3"
                        placeholder={translate('addressLine3')}
                        value={form.addressLine3}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field name="town" hideLabel required>
                    <TextInput
                        required
                        name="town"
                        placeholder={translate('town')}
                        value={form.town}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field name="country" hideLabel required>
                    <Select
                        required
                        name="country"
                        placeholder={translate('country')}
                        value={form.country}
                        onChange={handleChange}
                        classes="generic-input"
                        options={countryOptions}
                        disabled
                    />
                </Field>
                <Field name="postcode" hideLabel required>
                    <TextInput
                        required
                        name="postcode"
                        placeholder={translate('postcode')}
                        value={form.postcode}
                        onChange={handleChange}
                        classes="generic-input"
                        disabled
                    />
                </Field>
                <Field name="tel1" hideLabel required>
                    <TextInput
                        required
                        name="tel1"
                        placeholder={translate('telephone1')}
                        value={form.tel1}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
                <Field>
                    <TextInput
                        name="tel2"
                        placeholder={translate('telephone2')}
                        value={form.tel2}
                        onChange={handleChange}
                        classes="generic-input"
                    />
                </Field>
            </Form>
        </ModalOuterContainer>
    );
};

export default AddCardHolderModal;
