import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import analytics from '../analytics';

const useGoogleAnalytics = () => {
    const history = useHistory();
    const { pathname, search } = useLocation();

    useEffect(() => {
        analytics.init();
        analytics.sendPageview(pathname + search);

        return history.listen(({ pathname, search }) => {
            analytics.sendPageview(pathname + search);
        });
    }, [history, pathname, search]);
};

export default useGoogleAnalytics;
