import React, { memo } from 'react';
import SwitchWith404 from './SwitchWith404';
import { Route } from 'react-router-dom';
import SubRouter from './SubRouter';
import WithAuth from 'components/shared/auth/hocs/withAuth';
import { ActivationGuard } from '../../shared/auth/hocs/ActivationGuard';

const RootStack = ({ stack }) => {
    let renderStack = stack.map(item => (
        <Route key={item.base} path={item.base} component={getStack(item)} />
    ));
    return <SwitchWith404>{renderStack}</SwitchWith404>;
};

function getStack({ base, stack, auth }) {
    let Stack = () => {
        if (!auth) {
            return (
                <ActivationGuard>
                    <SubRouter base={base} routes={stack} />
                </ActivationGuard>
            );
        }
        return (
            <WithAuth>
                <SubRouter base={base} routes={stack} />
            </WithAuth>
        );
    };
    return Stack;
}

export default memo(RootStack);
