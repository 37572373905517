import { createReducer } from '@reduxjs/toolkit';
import { toggleDarkMode } from 'actions/isDarkModeEnabled';

const initialState = {
    isDarkModeEnabled: false,
};

export default createReducer(initialState, {
    [toggleDarkMode]: handleIntitaliseTheme,
});

function handleIntitaliseTheme(state, action) {
    state.isDarkModeEnabled = action.payload;
}
