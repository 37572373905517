import useCountdownTimer from 'lib/hooks/useCountdownTimer';
import { Email } from '../presentational/Email';
import { useForm } from 'lib/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {  handleSignIn } from 'lib/utils/amplify';
import { AUTH_CHECKING } from '../../../../constants/shared/routes';
import { useState } from 'react';
import { getUserInfo } from 'utils/auth';
import ResetButton from 'components/shared/layout/resetButton/ResetButton';


export const EmailContainer = () => {
    const [form, handleChange] = useForm({ code: '' });
    const [error, setError] = useState<string>('');
    const {
        remainingSeconds,
        startCountdown,
        isCountdownActive,
    } = useCountdownTimer(30);
    const history = useHistory();

    const handleSubmit = () => {
        const code = form.code.trim();
        history.push(`${AUTH_CHECKING}/${code}`);
    };

    const handleResend = async () => {
        startCountdown();
        try {
            const userInfo = getUserInfo();
            userInfo &&  await handleSignIn(userInfo);
        } catch (e) {
            const error = e as { name:string ,message: string };
            setError(error.message);
        }
    };

    return (
        <>
            <Email
                error={error}
                code={form.code}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleResend={handleResend}
                remainingSeconds={remainingSeconds}
                isCountdownActive={isCountdownActive}
            />
            <ResetButton/>
        </>
    );
};
