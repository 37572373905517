import React from 'react';
import InputMask from 'react-input-mask';
import withFieldValidation from '../hocs/withFieldValidation';

const MaskInput = ({
    name,
    placeholder,
    classes,
    value,
    onChange,
    disabled,
    showError,
    mask,
}) => (
    <InputMask
        mask={mask}
        maskChar={null}
        className={classes}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={newVal => {
            onChange(name, newVal.target.value);
        }}
        onBlur={showError}
        disabled={disabled}
    />
);

export default withFieldValidation(MaskInput);
