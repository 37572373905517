import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { selectPayee } from 'lib/actions';
import { PAYMENT_MAKE_PAYMENT } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import PaymentSuccess from '../presentational/PaymentSuccess';

const PaymentSuccessContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleSuccessClick = () => {
        history.push(PAYMENT_MAKE_PAYMENT);
    };

    useEffect(() => {
        return () => {
            dispatch(selectPayee(null));
        };
    }, [dispatch]);

    return (
        <PageContainer>
            {location.state && location.state.data && (
                <PaymentSuccess
                    data={location.state.data}
                    onSuccessClick={handleSuccessClick}
                />
            )}
        </PageContainer>
    );
};

export default PaymentSuccessContainer;
