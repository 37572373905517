import React from 'react';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import Help from '../presentational/Help';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';

const HelpContainer = () => {
    return (
        <PageContainer className="help-container">
            <SectionTitle title="Help" />
            <Help />
        </PageContainer>
    );
};

export default HelpContainer;
