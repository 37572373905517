import React from 'react';

import useTranslate from 'lib/languages/useTranslate';
import { useHistory } from 'react-router-dom';

const SectionTitle = ({
    tag,
    title,
    secondary = false,
    backRoute,
    backState = {},
    additionalText,
    goBack,
}) => {
    const history = useHistory();
    const TagName = tag ? tag : secondary ? 'h3' : 'h1';
    const translate = useTranslate('generic');

    const handleBackClick = () => {
        if (goBack) {
            history.goBack();
        } else if (!backState) {
            history.push(backRoute);
        } else {
            history.push({ pathname: backRoute, state: backState });
        }
    };

    if (secondary) return <TagName className="section-title">{title}</TagName>;

    return (
        <TagName className="section-title">
            {title}
            {(backRoute || goBack) && (
                <span onClick={handleBackClick}>
                    <i className="fa fa-arrow-left"></i>
                    {translate('back')}
                </span>
            )}
            {additionalText && <p className="info">{additionalText}</p>}
        </TagName>
    );
};

export default SectionTitle;
