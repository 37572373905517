import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

const Checkbox = ({
    value = false,
    checked = false,
    onChange,
    name,
    text = '',
    classes = '',
    disabled = false,
}) => (
    <div className={`checkbox ${disabled ? 'disabled' : ''} ${classes}`}>
        <label>
            <input
                id={name}
                onChange={newVal => {
                    onChange(name, newVal.target.checked);
                }}
                type="checkbox"
                value={value}
                checked={checked}
                name={name}
                disabled={disabled}
            />
            <span>{!!text.length && <span className="text">{text}</span>}</span>
        </label>
    </div>
);

export default withFieldValidation(Checkbox);
