import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAccountDormantModal from 'components/accountStatus/hooks/useAccountDormantModal';

import useGoogleAnalytics from 'utils/hooks/useGoogleAnalytics';
import useIsPrivateRoute from 'components/routes/hocs/useIsPrivateRoute';

import { setLanguageCode } from 'lib/actions';
import { getShowAccountBlockedModal } from 'lib/selectors';
import { getTheme } from 'selectors/darkMode';
import { toggleTheme } from 'actions/isDarkModeEnabled';

import AppRoutes from 'components/routes';
import ModalRoute from 'components/shared/modals/generic/containers/ModalRoot';
import GlobalRedirect from 'components/shared/generic/GlobalRedirect';
import HeaderContainer from 'components/shared/layout/header/containers/HeaderContainer';
import AuthorizedHeader from 'components/shared/layout/authorizedHeader';
import HelpButton from 'components/shared/layout/helpButton/HelpButton';
import AccountStatusModal from 'components/accountStatus/AccountStatusModal';
import useGenericErrorModal from 'utils/hooks/useGenericErrorsModal';
import useIsPpsAccountBlocked from 'utils/hooks/useIsPpsAccountBlocked';
import { usePrevious } from 'lib/utils';
import { hideModal } from 'actions/modal';
import { useLocation } from 'react-router-dom';

function App() {
    const dispatch = useDispatch();
    const isPrivateRoute = useIsPrivateRoute();
    const isDarkModeEnabled = useSelector(getTheme);
    const isBlocked = useSelector(getShowAccountBlockedModal);
    const prevBlocked = usePrevious(isBlocked);
    const { showAccountBlockedModal } = useIsPpsAccountBlocked();
    const [showDormantModal, handleClose] = useAccountDormantModal();
    const location = useLocation();
    useGoogleAnalytics();

    const path = location.pathname;

    useGenericErrorModal(); // Show generic modal for 500 errors

    const handleThemeCheck = useCallback(() => {
        const item =
            window.localStorage.getItem('isDarkModeEnabled') === 'true';

        dispatch(toggleTheme(item));
    }, [dispatch]);

    const handleLanguageCheck = useCallback(() => {
        const item = JSON.parse(window.localStorage.getItem('languageCode'));

        if (item) {
            dispatch(setLanguageCode(item));
        }
    }, [dispatch]);

    useEffect(() => {
        handleThemeCheck();
        handleLanguageCheck();
    }, [handleThemeCheck, handleLanguageCheck]);

    useEffect(() => {
        if (isBlocked && !prevBlocked) {
            dispatch(hideModal());
            showAccountBlockedModal();
        }
    }, [prevBlocked, isBlocked, dispatch, showAccountBlockedModal]);

    const renderHeader = () => {
        if (isPrivateRoute) {
            return <AuthorizedHeader />;
        }

        if (path !== '/auth') {
            return <HeaderContainer />;
        }

        return null;
    };

    return (
        <div className="app">
            <div id="page-container">
                {renderHeader()}
                {showDormantModal && (
                    <AccountStatusModal handleClose={handleClose} />
                )}
                <HelpButton />
                <div id="page-routes">
                    <div
                        className={`page-body ${
                            isPrivateRoute ? 'authed' : ''
                        } ${isDarkModeEnabled ? 'dark-mode' : ''}`}
                    >
                        <AppRoutes />
                        <ModalRoute />
                        <GlobalRedirect />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
