import React from 'react';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../containers/ModalOuterContainer';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';

const SuccessModal = ({
    message = 'Task completed',
    title = 'Success',
    hideModal,
}) => (
    <ModalOuterContainer customButtons className="status-modal">
        <div className="description">
            <Typography tag="h1">{title}</Typography>
            <div className="modal-icon">
                <i className="fa fa-check-circle" />
            </div>
            <Typography className="generic-text intro-text">
                {message}
            </Typography>
        </div>
        <ButtonContainer className="center">
            <Button onClick={hideModal}>Close</Button>
        </ButtonContainer>
    </ModalOuterContainer>
);

export default SuccessModal;
