import React from 'react';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const PersonalDetails = ({
    data,
    editClick /*showPrimaryTaxChange <-- For primary tax */,
}) => {
    const {
        title,
        forename,
        surname,
        buildingNo,
        buildingName,
        streetAddress,
        town,
        postcode,
        country,
        countryCode,
        email,
        diallingCode,
        mobile,
        // primaryTaxResidency, <-- For primary tax
    } = data;
    const translate = useTranslate('personalDetails');

    return (
        <>
            <div className="personal-details">
                <Typography tag="h1">{translate('name')}</Typography>
                <Typography>{`${title} ${forename} ${surname}`}</Typography>
            </div>
            <div className="personal-details">
                <Typography tag="h1">{translate('email')}</Typography>
                <Typography>{email}</Typography>
            </div>
            <div className="personal-details spacing-bottom">
                <Typography tag="h1">{translate('mobile')}</Typography>
                <Typography>{diallingCode}{mobile}</Typography>
            </div>
            <div className="personal-details spacing-bottom">
                <Typography tag="h1">{translate('address')}</Typography>
                <Typography>
                    {[buildingName, buildingNo, streetAddress]
                        .filter(field => field)
                        .join(' ')}
                    ,
                </Typography>
                <Typography>{town},</Typography>
                <Typography>{country},</Typography>
                {countryCode && <Typography>{countryCode},</Typography>}
                <Typography>{postcode}</Typography>
            </div>
            <ButtonContainer>
                <Button onClick={editClick}>{translate('edit')}</Button>
            </ButtonContainer>
        </>
    );
};

export default PersonalDetails;
