import React from 'react';

const Right = ({ className = '', onClick, disabled }) => {
    const handleClick = e => {
        if (!disabled) onClick(e);
    };
    return (
        <span
            className={`arrow-button right ${className} ${
                disabled ? 'disabled' : ''
            }`}
            onClick={handleClick}
        >
            <i className="caret fas fa-caret-right" />
        </span>
    );
};

export default Right;
