import { Activated } from '../presentational/Activated';
import { useHistory } from 'react-router-dom';
import { finalizeAuthAndSetToken } from 'utils/auth';
import ResetButton from 'components/shared/layout/resetButton/ResetButton';

export const ActivatedContainer = () => {
    const history = useHistory();

    const onNext = () => {
        const cognitoToken = localStorage.getItem('cognitoToken');

        if (cognitoToken) {
            finalizeAuthAndSetToken(cognitoToken);
            history.push('/');
        } else {
            console.error('Cognito token not found');
        }
    };
    return (
        <>
            <Activated onNext={onNext} />
            <ResetButton />
        </>
    );
};
