import React from 'react';

import SubscriptionCard from './SubscriptionCard';

const ManageSubscription = ({ productType, pricePlans }) => {
    return (
        <div className="subscriptions-list">
            {pricePlans.map(role => (
                <SubscriptionCard
                    key={role.productClassCode}
                    role={role}
                    productType={productType}
                    isCurrentSubscription={
                        productType === role.productClassCode
                    }
                />
            ))}
        </div>
    );
};

export default ManageSubscription;
