import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'lib/utils';
import { getPayeesIsPosting, getPayeesPostSuccess } from 'lib/selectors/payees';
import { resetPayeeState } from 'lib/slices/payeeSlice';
import { useHistory } from 'react-router-dom';
import LoadingText from 'components/shared/generic/loader/LoadingText';
import { PAYMENT_MANAGE_PAYEES } from '../../../../../constants/shared/routes';
import useTranslate from 'lib/languages/useTranslate';

const PayeeCreationWrapper = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const postSuccess = useSelector(getPayeesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const isPosting = useSelector(getPayeesIsPosting);
    const isPayeeCreated = postSuccess && !prevPostSuccess;

    const translate = useTranslate('addPayee');

    useEffect(() => {
        if (isPayeeCreated) {
            history.push(PAYMENT_MANAGE_PAYEES);
            dispatch(resetPayeeState());
        }
    }, [prevPostSuccess, postSuccess, dispatch, history, isPayeeCreated]);

    if (isPosting) {
        return <LoadingText text={translate("loadingCreatePayee")} />;
    }

    if (isPayeeCreated) {
        return null;
    }

    return children;
};

export default PayeeCreationWrapper;
