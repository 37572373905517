import Button, {
    ButtonTypes,
} from 'components/shared/generic/button/presentational/Button';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFieldErrors, clearFormError, showFieldErrors } from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import { getFieldErrors, getFormError } from 'lib/selectors';
import { getTheme } from 'selectors/darkMode';
import { isEmpty } from 'lib/utils';
import analytics from 'utils/analytics';
import Field from './Field';

enum TrackingAction {
    FORM_CLICK = 'FORM_CLICK',
    FORM_SUBMIT = 'FORM_SUBMIT',
}

interface IProps {
    className?: string;
    styles?: Object;
    children: JSX.Element | JSX.Element[];
    error: any;
    omitButtons?: boolean;
    isPosting: boolean;
    submitText?: string;
    cancelText?: string;
    buttonSize?: string;
    buttonMt?: string;
    onSubmit: () => {} | void;
    onCancel?: () => {};
    tracking?: boolean;
    trackingAction?: TrackingAction | null;
    trackingLabel?: TrackingAction | null;
    disabledButton?: boolean;
}

const Form = ({
    className = '',
    styles = {},
    children,
    error,
    omitButtons = false,
    isPosting,
    submitText,
    cancelText,
    buttonSize = 'normal',
    buttonMt = '16px',
    onSubmit,
    onCancel,
    tracking,
    trackingAction = TrackingAction.FORM_CLICK,
    trackingLabel = TrackingAction.FORM_SUBMIT,
    disabledButton = false,
}: IProps) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const genericError = useSelector(getFormError) as string;
    const isDarkModeEnabled = useSelector(getTheme);

    useEffect(() => {
        return () => {
            dispatch(clearFormError());
            dispatch(clearFieldErrors());
        };
    }, [dispatch]);

    const translate = useTranslate('generic');
    return (
        <form
            className={`sm-form ${className}`}
            style={styles}
            onSubmit={_handleSubmit}
        >
            {children}
            {!!genericError && (
                <Field>
                    <p className="error">{genericError}</p>
                </Field>
            )}
            {!!error && (
                <Field>
                    <p className="error text-center">{!error.message ? error : error.message}</p>
                </Field>
            )}

            {!omitButtons && (
                <ButtonContainer marginTop={buttonMt}>
                    {!!onCancel && (
                        <Button
                            type={ButtonTypes.BUTTON}
                            className="cancel"
                            onClick={_handleCancel}
                            disabled={isPosting}
                        >
                            {cancelText ?? translate('close')}
                        </Button>
                    )}
                    <Button
                        size={buttonSize}
                        isPosting={isPosting}
                        type={ButtonTypes.SUBMIT}
                        className={`form-submit center ${
                            isDarkModeEnabled ? 'dark' : 'light'
                        }`}
                        disabled={disabledButton}
                    >
                        {submitText ?? translate('confirm')}
                    </Button>
                </ButtonContainer>
            )}
        </form>
    );

    function _handleCancel(e) {
        e.preventDefault();

        if (!isPosting && onCancel) {
            onCancel();
        }
    }

    function _handleSubmit(e) {
        e.preventDefault();

        if (tracking) {
            analytics.sendFormEvent(trackingAction, trackingLabel);
        }

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

export default Form;
