import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deletePayee, fetchPayees, payeeErrorClear } from 'lib/actions';
import {
    getPayees,
    getPayeesIsFetching,
    getPayeesError,
    getPayeesIsPosting,
    getLastPayeePage,
    getPayeesPostError,
    getPayeesDeleteError
} from 'lib/selectors';
import { hideModal, showModal } from 'actions/modal';
import { CONFIRM_MODAL, DISMISS_MODAL } from 'constants/shared/modalTypes';
import {
    PAYMENT,
    PAYMENT_ADD_PAYEE,
    PAYMENT_MANAGE_PAYEES,
} from 'constants/shared/routes';

import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import ManagePayees from '../presentational/ManagePayees';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';
import { usePrevious } from 'lib/utils';

const ManagePayeesContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const last = useSelector(getLastPayeePage);
    const payee = useSelector(getPayees);
    const isFetching = useSelector(getPayeesIsFetching);
    const error = useSelector(getPayeesError);
    const deleteError = useSelector(getPayeesDeleteError);
    const isPosting = useSelector(getPayeesIsPosting);
    const postError = useSelector(getPayeesPostError);
    const prevPostError = usePrevious(postError);
    const translate = useTranslate('managePayees');

    const handleFetchPage = (page = 1) => {
        dispatch(fetchPayees(page));
    };

    const handleAddNewPayee = () => {
        history.push(PAYMENT_ADD_PAYEE, {
            backRoute: PAYMENT_MANAGE_PAYEES,
        });
    };

    const handleDelete = payee => {
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    title: translate('removePayee'),
                    message: translate('confirmationMessage'),
                    handleSubmit: () => {
                       dispatch(deletePayee(payee))
                       dispatch(hideModal())
                    },
                    isPosting,
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchPayees());
    }, [dispatch]);

    useEffect(() => {
        const handleDeleteError = () => {
            if (deleteError) {
                dispatch(
                    showModal({
                        type: DISMISS_MODAL,
                        props: {
                            title: translate('removePayee'),
                            message: translate('errorDeleteError'),
                            handleCancel: () => {
                                dispatch(hideModal());
                                dispatch(payeeErrorClear());
                            },
                        },
                    }),
                );
            }
        };

        if (!prevPostError && deleteError) {
            dispatch(hideModal());
            handleDeleteError();
        }
    }, [deleteError, prevPostError, translate, dispatch]);

    return (
        <PageContainer className="manage-payee-container">
            <SectionTitle title={translate('titleText')} backRoute={PAYMENT} />
            <DataCheck data={payee} isLoading={isPosting || isFetching} error={error}>
                <ManagePayees
                    data={payee}
                    last={last}
                    handleDelete={handleDelete}
                    handleFetchPage={handleFetchPage}
                    noDataMessage={translate('noDataMessage')}
                />
            </DataCheck>
            <ButtonContainer>
                <Button onClick={handleAddNewPayee} className="center">
                    {translate('addNewPayee')}
                </Button>
            </ButtonContainer>
        </PageContainer>
    );
};

export default ManagePayeesContainer;
