import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStandingOrders } from 'lib/actions';
import {
    getStandingOrderError,
    getStandingOrderIsFetching,
    getStandingOrders,
} from 'lib/selectors';
import { PAYMENT, PAYMENT_STANDING_ORDER_PAYEE } from 'constants/shared/routes';
import StandingOrder from '../presentational/StandingOrder';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';


const StandingOrderContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const standingOrders = useSelector(getStandingOrders);
    const isFetching = useSelector(getStandingOrderIsFetching);
    const error = useSelector(getStandingOrderError);
    const translate = useTranslate('standingOrders');

    const handleAddClick = () => {
        history.push(PAYMENT_STANDING_ORDER_PAYEE);
    };

    useEffect(() => {
        dispatch(fetchStandingOrders());
    }, [dispatch]);

    return (
        <>
            <SectionTitle
                title={translate('standingOrders')}
                backRoute={PAYMENT}
            />
            <DataCheck
                data={standingOrders}
                isLoading={isFetching}
                error={error}
                noDataMessage={translate('noDataMessage')}
            >
                <StandingOrder data={Object.values(standingOrders)} />
            </DataCheck>
            <Button className="center" onClick={handleAddClick}>
                {translate('setupStandingOrder')}
            </Button>
        </>
    );
};

export default StandingOrderContainer;
