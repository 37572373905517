import React from 'react';
import dayjs from 'dayjs';
import { standingOrdersRecurringNames } from 'lib/constants';
import { formatSortCode } from 'lib/utils';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const StandingOrderItem = ({ data, handleDelete }) => {
    const translate = useTranslate('standingOrders.singleStandingOrder');
    return (
        <div className="standing-order-container">
            <div className="view-standing-order-container">
                <div className="view-item">
                    <h2>
                        {translate('status')}:{' '}
                        {!data.isDeleted
                            ? translate('active')
                            : translate('cancelled')}
                    </h2>
                </div>
                <div className="view-item">
                    <h2>{translate('accountNumber')}</h2>
                    <h3>{data.bankAccountNo}</h3>
                </div>
                <div className="view-item">
                    <h2>{translate('sortCode')}</h2>
                    <h3>{formatSortCode(data.sortCode)}</h3>
                </div>
                <div className="view-item">
                    <h2>{translate('reccurence')}</h2>
                    <h3>{standingOrdersRecurringNames[data.recurType]}</h3>
                </div>
                <div className="view-item">
                    <h2>{translate('startOn')}</h2>
                    <h3>{dayjs(data.startOn).format('DD MMM YYYY')}</h3>
                </div>
                <div className="view-item">
                    <h2>{translate('endOn')}</h2>
                    <h3>{data.endOn ? dayjs(data.endOn).format('DD MMM YYYY') : '-'}</h3>
                </div>
                <div className="view-item">
                    <h2>{translate('lastPaymentSent')}</h2>
                    <h3>
                        {!data.lastRunOn || data.lastRunOn === null
                            ? '-'
                            : dayjs(data.lastRunOn).format('DD MMM YYYY')}
                    </h3>
                </div>
            </div>

            {!data.isDeleted && (
                <Button onClick={handleDelete} className="danger">
                    {translate('cancelStandingOrder')}
                </Button>
            )}
        </div>
    );
};

export default StandingOrderItem;
