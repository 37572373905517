import { createReducer } from '@reduxjs/toolkit';
import { showModal, hideModal } from 'actions/modal';

const initialState = {
    type: null,
    props: {},
};

export default createReducer(initialState, {
    [showModal]: handleShow,
    [hideModal]: handleClose,
});

function handleShow(state, action) {
    state.type = action.payload.type;
    state.props = action.payload.props || {};
}

function handleClose(state) {
    state.type = null;
    state.props = {};
}
