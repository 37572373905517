import React from 'react';
import { useSelector } from 'react-redux';
import {
    getAccount,
    getAccountIsFetching,
    getAccountError,
} from 'lib/selectors';
import { formatAccount } from 'utils/generic';

import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import CardsCarouselContainer from './CardsCarouselContainer';

const CardsContainer = () => {
    const state = useSelector(state => state);
    const accountState = getAccount(state);
    const isLoadingAccount = getAccountIsFetching(state);
    const accountError = getAccountError(state);

    return (
        <PageContainer center className="cards-container">
            <DataCheck
                data={accountState}
                isLoading={isLoadingAccount}
                error={accountError}
                noDataMessage="You currently have no cards"
            >
                <CardsCarouselContainer account={formatAccount(accountState)} />
            </DataCheck>
        </PageContainer>
    );
};

export default CardsContainer;
