import React from 'react';
import { useDispatch } from 'react-redux';

import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Settings from '../presentational/Settings';

import { toggleTheme } from 'actions/isDarkModeEnabled';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import useUpdateLanguage from '../hooks/useUpdateLanguage';
import useTranslate from 'lib/languages/useTranslate';
import { testErrors } from 'lib/actions';

const SettingsContainer = () => {
    const dispatch = useDispatch();
    const [language, handleLanguageChange] = useUpdateLanguage();

    const [, setIsDarkModeEnabled] = useLocalStorage('isDarkModeEnabled', {});
    const isDarkModeEnabled = useSelector(getTheme);
    const translate = useTranslate('appSettings');

    return (
        <PageContainer className="more-settings-container">
            <SectionTitle title={translate('settings')} backRoute={MORE} />
            <Settings
                isDarkModeEnabled={isDarkModeEnabled}
                handleDarkModeToggle={handleDarkModeToggle}
                selectedLanguage={language}
                handleLanguageChange={handleLanguageChange}
                handleTestErrors={handleTestErrors}
            />
        </PageContainer>
    );

    function handleDarkModeToggle(isDarkModeEnabled) {
        setIsDarkModeEnabled(isDarkModeEnabled);
        dispatch(toggleTheme(isDarkModeEnabled));
    }

    function handleTestErrors() {
        dispatch(testErrors());
    }
};

export default SettingsContainer;
