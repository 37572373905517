import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';
import CurrencyInput from 'react-currency-input-field';

const CurrencyInputField = ({
    type = "text",
    name,
    placeholder,
    value,
    onChange,
    prefix,
    classes = '',
    validate
}) => {
    return (
        <CurrencyInput
            decimalSeparator="." 
            groupSeparator=","
            className={`input-currency ${classes}`}
            placeholder={placeholder}
            validate={validate}
            value={value}
            allowNegativeValue={false}
            prefix={prefix}
            decimalScale={2}
            onValueChange={value => {
                onChange(name, value);
            }}
        />
    );
};

export default withFieldValidation(CurrencyInputField);
