import { AccountActivation } from '../presentational/AccountActivation';
import { useForm } from 'lib/utils';
import { useActivateAccountMutation } from 'lib/services/account';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AUTH_EMAIL } from '../../../../constants/shared/routes';
import { setAuthStep } from 'utils/generic';
import { AuthStep } from 'constants/shared/enums';
import { getUserInfo } from 'utils/auth';
import { renewUserAuthentication } from 'lib/utils/amplify';
import ResetButton from 'components/shared/layout/resetButton/ResetButton';

export const AccountActivationContainer = () => {
    const history = useHistory();
    const [
        activateAccount,
        { isLoading, error },
    ] = useActivateAccountMutation();
    const userInfo = getUserInfo();
    const [form, handleChange] = useForm({
        activationCode: '',
        dob: '',
    });

    const handleSubmit = async () => {
        try {
            await activateAccount(form).unwrap();
            userInfo && (await renewUserAuthentication(userInfo));
            setAuthStep(AuthStep.EMAIL_ACTIVATION_FLOW);
            history.push(AUTH_EMAIL);
        } catch (error) {
            console.error('rejected', error);
        }
    };

    return (
        <>
            <AccountActivation
                {...form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isPosting={isLoading}
                error={error && 'data' in error ? error.data : null}
            />
            <ResetButton />
        </>
    );
};
