import { hideModal } from 'actions/modal';
import DatePicker from 'components/shared/form/presentational/DatePicker';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import Select from 'components/shared/form/presentational/Select';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { StatementType } from 'constants/shared/enums';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch } from 'react-redux';
import useTranslate from 'lib/languages/useTranslate';
import { api, useForm } from 'lib/utils';
import { downloadFile } from 'utils/generic';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';

const statementTypeOptions = Object.entries(
    StatementType,
).map(([key, value]) => ({ value, label: key }));

const ExportStatementModal = () => {
    const dispatch = useDispatch();
    const [{ statementType, dateFrom, dateTo }, handleChange] = useForm({
        statementType: StatementType.PDF,
        dateFrom: dayjs()
            .subtract(1, 'month')
            .toDate(),
        dateTo: dayjs().toDate(),
    });

    const downloadStatement = () => {
        const start = dayjs(dateFrom).format('YYYY-MM-DD');
        const end = dayjs(dateTo).format('YYYY-MM-DD');
        let fileName = `Statement-${start}-${end}`;
        if (statementType === StatementType.PDF) fileName += '.pdf';
        if (statementType === StatementType.CSV) fileName += '.csv';

        api.download(
            `statement?dateFrom=${start}&dateTo=${end}&format=${statementType}`,
        ).then( async (res) => {
            const text = await res.data.text()
            downloadFile(b64toBlob(text), fileName);
            dispatch(hideModal());
        });
    };

    // temporary solution
    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
      
        const byteCharacters = atob(b64Data.split(',')[1]);
        let byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          let byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
      
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    const translate = useTranslate('exportStatementModal');

    return (
        <ModalOuterContainer customButtons>
            <Form
                onSubmit={downloadStatement}
                onCancel={() => dispatch(hideModal())}
                submitText={translate('submit')}
                cancelText={translate('cancel')}
            >
                <SectionTitle title={translate('titleText')} />
                <Field name={translate('statementType')} required>
                    <Select
                        classes="generic-input modal-select"
                        omitPlaceholder
                        required
                        name="statementType"
                        onChange={handleChange}
                        value={statementType}
                        options={statementTypeOptions}
                    />
                </Field>
                <Field name={translate('dateFrom')}>
                    <DatePicker
                        classes="generic-input"
                        value={dateFrom}
                        onChange={handleChange}
                        name="dateFrom"
                    />
                </Field>
                <Field name={translate('dateTo')}>
                    <DatePicker
                        classes="generic-input"
                        value={dateTo}
                        onChange={handleChange}
                        name="dateTo"
                        maxDate={new Date()}
                    />
                </Field>
            </Form>
        </ModalOuterContainer>
    );
};

export default ExportStatementModal;
