import React from 'react';

import useTranslate from 'lib/languages/useTranslate';

const NewCard = ({ onAddCardHolderClick, isDarkText }) => {
    const translate = useTranslate('cards.cardSlider');
    return (
        <div
            className={`content new-card ${isDarkText ? 'dark-text' : ''}`}
            onClick={onAddCardHolderClick}
        >
            <i className="icon fa fa-plus"></i>
            <p className="title">{translate('requestAdditionalCardHolder')}</p>
        </div>
    );
};

export default NewCard;
