import { useDispatch } from 'react-redux';
import { createPayee } from 'lib/actions';
import { PayeeType } from 'lib/type/enums';

interface CreatePayeeProps {
    name: string;
    type: PayeeType;
    accountNumber: string;
    sortCode: string;
}

export const useCreatePayee = () => {
    const dispatch = useDispatch();
    const handleCreatePayee = (data: CreatePayeeProps) => {
        const {
            name: payeeName,
            type,
            accountNumber: payeeAccountNo,
            sortCode,
        } = data;

        dispatch(createPayee({ payeeName, type, payeeAccountNo, sortCode }));
    };

    return {
        handleCreatePayee,
    };
};
