import React from 'react';
import Typography from '../../typography/presentational/Typography';

const BulletPoint = ({
    className,
    small,
    leftText,
    rightText,
    html,
    dash,
    ...props
}) => {
    const classes = [];
    if (small) classes.push('small');
    if (className) classes.push(className);

    if (html) {
        return (
            <div {...props} className={`sm-bullet-point ${classes.join(' ')}`}>
                <div className="left">
                    <span className={`bullet-point ${dash ? 'dash' : ''}`} />
                    <Typography
                        dangerouslySetInnerHTML={{ __html: leftText }}
                    />
                </div>
                <span className="right">
                    <Typography>{rightText}</Typography>
                </span>
            </div>
        );
    }

    return (
        <div {...props} className={`sm-bullet-point ${classes.join(' ')}`}>
            <div className="left">
                <span className={`bullet-point ${dash ? 'dash' : ''}`} />
                <Typography>{leftText}</Typography>
            </div>
            <span className="right">
                <Typography>{rightText}</Typography>
            </span>
        </div>
    );
};

export default BulletPoint;
