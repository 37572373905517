import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    PAYMENT_MAKE_PAYMENT,
    PAYMENT_STANDING_ORDER,
    PAYMENT_DIRECT_DEBIT,
    PAYMENT_ADD_CASH,
    MORE_ACCOUNT_DETAILS,
    PAYMENT_MANAGE_PAYEES
} from 'constants/shared/routes';
import Container from 'components/shared/generic/container/presentational/Container';
import Payments from '../presentational/Payments';
import { getTheme } from 'selectors/darkMode';

const PaymentsContainer = () => {
    const history = useHistory();
    const isDarkModeEnabled = useSelector(getTheme);

    const handleMakePayment = () => history.push(PAYMENT_MAKE_PAYMENT);
    const handleViewStandingOrders = () => history.push(PAYMENT_STANDING_ORDER);
    const handleManagePayee = () => history.push(PAYMENT_MANAGE_PAYEES);
    const handleViewDirectDebits = () => history.push(PAYMENT_DIRECT_DEBIT);
    const handleAddCash = () => history.push(PAYMENT_ADD_CASH);
    const handleSalary = () => history.push(MORE_ACCOUNT_DETAILS);

    return (
        <Container>
            <Payments
                onMakePaymentClick={handleMakePayment}
                onManagePayeeClick={handleManagePayee}
                onStandingOrdersClick={handleViewStandingOrders}
                onDirectDebitClick={handleViewDirectDebits}
                onAddCashClick={handleAddCash}
                onSalaryClick={handleSalary}
                isDarkModeEnabled={isDarkModeEnabled}
            />
        </Container>
    );
};

export default PaymentsContainer;
