import Transactions from 'components/activity/transactions/main/containers/TransactionsContainer';
import PendingTransactionsList from '../../activity/transactions/main/presentational/PendingTransactionsList';

const activityRoutes = [
    { path: '', component: Transactions, pageTitle: 'Home' },
    {
        path: '/activity/pending',
        component: PendingTransactionsList,
        pageTitle: 'Pending Transactions',
    },
];

export default activityRoutes;
