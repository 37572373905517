import React from 'react';

import { useSelector } from 'react-redux';
import { useFetchPricePlans } from 'lib/hooks';

import {
    getAccount,
    getAccountError,
    getAccountIsFetching,
} from 'lib/selectors';

import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import ManageSubscription from '../presentational/ManageSubscription';
import useTranslate from 'lib/languages/useTranslate';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';

const ManageSubscriptionContainer = () => {
    const translate = useTranslate('pricePlan');

    const account = useSelector(getAccount);
    const isFetching = useSelector(getAccountIsFetching);
    const error = useSelector(getAccountError);
    const {
        pricePlans,
        isFetching: isFetchingPricePlans,
        error: pricePlansError,
    } = useFetchPricePlans();

    return (
        <PageContainer className="more-manage-subscription-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={MORE}
            />
            <DataCheck
                data={account || pricePlans}
                isLoading={isFetching || isFetchingPricePlans}
                error={error || pricePlansError}
                renderIfNotEmpty
                noDataMessage={translate('noDataMessage')}
            >
                <ManageSubscription
                    productType={account.productType}
                    pricePlans={pricePlans}
                />
            </DataCheck>
        </PageContainer>
    );
};

export default ManageSubscriptionContainer;
